import { TFunction } from 'i18next';
import _ from 'lodash';

import {
  dateFormats,
  jsDateFromString,
  getDay,
  jsDateToString,
  differenceInMilliseconds,
} from 'core/utils/date';

import { getEventStartAndEnd } from '../agenda/FwMask.Agenda.helpers';
import { getMaskRowText } from '../FwMask.helpers';
import { DateViewType, MaskStructure } from '../FwMask.structures';
import { MaskRow } from '../FwMask.types';

const maskRowsToTimelineData = (
  maskStructure: MaskStructure,
  maskRows: MaskRow[],
  t: TFunction
) => {
  const {
    view,
    document: {
      groupBy,
      start: eventStart,
      length: eventLength,
      end: eventEnd,
    },
  } = maskStructure;

  // move all ungrouped mask rows to the default group ('')
  const defaultGroupBy = '';

  _.forEach(
    _.filter(maskRows, (mr) => !mr.data[groupBy]),
    (maskRow) => (maskRow.data[groupBy] = defaultGroupBy)
  );

  // select all groups as a sorted list
  const resources = _.sortBy(
    _.uniq(_.map(maskRows, (mr) => mr.data[groupBy])),
    (r) => _.toLower(r)
  );

  // build timeline rows (1 row per group = 1 row per resource)
  const data = _.map(resources, (r) => ({
    name: groupBy ? r : defaultGroupBy,
    view,
    labels: _.map(
      _.filter(maskRows, (mr) => mr.data[groupBy] === r),
      (maskRow) => {
        const {
          key,
          data: rowData,
          color,
          backgroundColor,
          borderColor,
          lastEdit,
        } = maskRow;

        const { start, end } = getEventStartAndEnd(rowData, lastEdit, {
          eventStart,
          eventLength,
          eventEnd,
        });

        // define text
        const text = getMaskRowText(maskStructure, rowData, t);

        return {
          key,
          text,
          color,
          backgroundColor,
          borderColor,
          start,
          end,
        };
      }
    ),
  }));

  return data;
};

const getDateDiff = (startDate: Date, endDate: Date) => {
  const startTime = getDay(startDate).start.getTime();
  const endTime = getDay(endDate).start.getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  // day diff
  return Math.round(Math.abs((endTime - startTime) / oneDay));
};

const getDefaultTime = (stringDate: string, numberTime: number) => {
  const jsDate = jsDateFromString(stringDate, dateFormats.datetime);

  // defautl time
  return jsDate ? jsDate.getHours() : numberTime;
};

const dateIsBetween = (date: Date, dateFrom: Date, dateTo: Date) => {
  const yearIsBetween =
    date.getFullYear() >= dateFrom.getFullYear() &&
    date.getFullYear() <= dateTo.getFullYear();
  const monthIsBetween =
    date.getMonth() >= dateFrom.getMonth() &&
    date.getMonth() <= dateTo.getMonth();

  return yearIsBetween && monthIsBetween;
};

const dateRangeToString = (startDate: Date, endDate: Date) =>
  jsDateToString(startDate, dateFormats.isoDate) +
  '|' +
  jsDateToString(endDate, dateFormats.isoDate);

const getHour = (
  viewHour: number,
  inf: number,
  sup: number,
  defaultHour: number
) => {
  return !_.isNil(viewHour) &&
    Number.isInteger(viewHour) &&
    viewHour >= inf &&
    viewHour <= sup
    ? viewHour
    : defaultHour;
};

const getTimeIndicator = (date: Date) => {
  return date.getHours() + date.getMinutes() / 60;
};

type Label = {
  end: Date;
  index: number;
  key: string;
  start: Date;
  text: string;
};

const sortLabels = (labels: Label[]) => {
  return _.sortBy(labels, [
    (lbl) => lbl.start,
    (lbl) => -lbl.end,
    (_lbl, idx) => idx,
  ]);
};

const setLabelsOverlapIndices = (labels: Label[]) => {
  let maxIndex = 0;
  const sortedLabels = sortLabels(labels) || [];

  for (let i = 0; i < sortedLabels.length; i++) {
    const currentLabel = sortedLabels[i];

    // find all labels that current label overlaps
    const overlaps = _.filter(
      sortedLabels,
      (l /* find label l such as... */) =>
        _.indexOf(sortedLabels, l) <
          i /* ...label l is placed before current label in sort... */ &&
        (l.end > currentLabel.start /* ...but ends after current label */ ||
          l.start.getTime() ===
            currentLabel.start.getTime()) /* ...or starts exactly when current label starts */
    );

    const overlappedIndices = _.sortedUniq(
      _.sortBy(_.map(overlaps, (l) => l.index || 0))
    );

    // optimize space by finding the minimal index where current label can fit
    let minIndex: number;

    for (let k = 0; k < overlappedIndices.length; k++) {
      const match = overlappedIndices[k] === k;

      if (!match) {
        // since there is no overlapped label at this index then current label will fit
        minIndex = k;
        break;
      }
    }

    currentLabel.index = /* if space was optimized... */ !_.isNil(minIndex)
      ? /* ...place at min index where it fits */ minIndex
      : /* otherwise use default positionning */ overlaps?.length || 0;

    // keep track of the maximum index
    if (currentLabel.index > maxIndex) {
      maxIndex = currentLabel.index;
    }
  }

  return maxIndex;
};

const getDefaultConflictThreshold = (view: `${DateViewType}`) => {
  return _.includes([DateViewType.day, DateViewType.week], view)
    ? /* 15 minutes */ 900000
    : /* 6 hours */ 21600000;
};

// a conflict in labels is when 2 labels start within a third of a slot
// i.e. they are too close to be seen correctly
const findConflict = (
  labels: Label[],
  slots: number,
  headers: { date?: Date }[],
  view?: `${DateViewType}`
) => {
  const headerSize =
    headers?.length && slots > 2 /* 1 header = 2 slots */
      ? differenceInMilliseconds(headers[1].date, headers[0].date)
      : /* only 1 header, use default conflict threshold */
        getDefaultConflictThreshold(view);
  const sortedLabels = sortLabels(labels) || [];
  const labelsSpacing: number[] = [];

  // find all label time differences to the next one, in ms
  for (let i = 1; i < sortedLabels.length; i++) {
    labelsSpacing.push(
      differenceInMilliseconds(sortedLabels[i].start, sortedLabels[i - 1].start)
    );
  }

  // search for all labels too close (less than a third of a slot) to their next label
  const conflictedLabels: Label[] = [];

  for (let j = 0; j < labelsSpacing.length; j++) {
    if (labelsSpacing[j] <= headerSize / (2 * 3)) {
      conflictedLabels.push(sortedLabels[j]);
    }
  }

  const found =
    conflictedLabels.length > 0
      ? /* find one  conflicted label with text */ _.find(
          conflictedLabels,
          (lbl) => !!lbl.text
        ) || /* otherwise, the first one */ conflictedLabels[0]
      : null;

  return found;
};

// use hour start view when not in MONTH mode
const getHourStartNumber = (view: `${DateViewType}`, hourNumber: number) => {
  return view === DateViewType.month ? 0 : hourNumber;
};

// use hour end view when not in MONTH mode
const getHourEndNumber = (view: `${DateViewType}`, hourNumber: number) => {
  return view === DateViewType.month ? 24 : hourNumber;
};

export {
  dateIsBetween,
  dateRangeToString,
  findConflict,
  getDateDiff,
  getDefaultTime,
  getHour,
  getHourEndNumber,
  getHourStartNumber,
  getTimeIndicator,
  maskRowsToTimelineData,
  setLabelsOverlapIndices,
  sortLabels,
};
