import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, InferProps, node, oneOf, string } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPES } from 'core/utils/constants';

const FwMessage: FC<Props> = ({ children, message, small, subtle, type }) => {
  const { t } = useTranslation();

  const alertStyle = {
    bg: subtle ? 'transparent' : undefined,
    variant: subtle ? undefined : 'left-accent',
    py: small ? 2 : undefined,
  };

  const titleStyle = {
    fontSize: small ? undefined : 'xl',
    fontWeight: small ? 'normal' : 'semibold',
  };

  return (
    <Alert {...alertStyle} status={type || undefined}>
      {type && <AlertIcon />}
      {message && (
        <AlertTitle {...titleStyle}>
          {t(message, { ns: ['glossary', 'common', 'custom'] })}
        </AlertTitle>
      )}
      {children}
    </Alert>
  );
};

const propTypes = {
  children: node,
  message: string,
  small: bool,
  subtle: bool,
  type: oneOf(_.values(MODAL_TYPES)),
};

export type Props = InferProps<typeof propTypes>;

FwMessage.propTypes = propTypes;

export default FwMessage;
