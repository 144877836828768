import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import appPreferenceApi from 'api/appPreference/appPreferenceApi';
import { useApi } from 'api/useApi';
import { i18n } from 'config/i18n';
import { defaultTheme } from 'config/theme/constants';
import getTokens from 'config/theme/getTokens';
import { mapAppPreferenceToSettings } from 'core/mapper';
import {
  AppPreference,
  FwSettingsProviderProps,
  fwSettingsProviderPT,
} from 'core/model';
import { defaultLocale } from 'core/utils/constant';
import { defaultCountryCode } from 'core/utils/date';
import {
  getTheme as readTheme,
  readSettings,
  setTheme as storeTheme,
  storeSettings,
} from 'core/utils/storage';

import { FwSettingsContext } from './FwSettingsContext';

export const defaultSettings = new FwSettingsProviderProps({
  accent: defaultTheme.accent,
  countryCode: _.toLower(defaultCountryCode),
  direction: defaultTheme.dir,
  isProd: process.env.NODE_ENV === 'production',
  language: defaultLocale,
  theme: defaultTheme.mode,
});

const FwSettingsProvider: FC<FwSettingsProviderProps> = ({
  children,
  ...initialSettings
}) => {
  const localStorageSettings = readSettings();

  // initialise settings from storage (if exists) or props (if no storage)
  const settingsRef = useRef(
    new FwSettingsProviderProps({
      ...defaultSettings,
      ...(localStorageSettings || initialSettings),
    })
  );

  const [settings, setSettings] = useState<FwSettingsProviderProps>({});

  // when no settings in local storage, fetch fasterClient perferences
  // in multi-client environment call get should be done by fasterClientID
  const [appPrefApiArgs, setAppPrefApiArgs] = useState(
    localStorageSettings ? [null] : []
  );
  const { fetched: clientPrefs, pending: pendingClientPrefs } = useApi(
    appPreferenceApi.get,
    appPrefApiArgs
  );

  // unified logic to set settings in storage and state
  const dispatchSettings = useCallback(
    (
      partialSettings?: FwSettingsProviderProps,
      setColorMode?: (theme: string) => void
    ) => {
      const fullSettings = new FwSettingsProviderProps(
        // merge received settings with existing ones
        _.assign(
          settingsRef.current,
          // remove nil values from received settings
          _.pickBy(partialSettings, (value) => !_.isNil(value))
        )
      );

      // apply language change
      const lang = fullSettings.language;

      if (lang && lang !== i18n.language) {
        i18n.changeLanguage(lang);
      }

      // apply theme mode change
      const themeMode = fullSettings.theme;
      const currentMode = readTheme();

      if (themeMode && themeMode !== currentMode) {
        storeTheme(themeMode);
        setColorMode?.(_.toLower(themeMode));
      }

      // update storage, ref and state
      storeSettings(fullSettings);
      settingsRef.current = fullSettings;
      setSettings(fullSettings);
    },
    []
  );

  const dispatchAppPreference = useCallback(
    (appPref: AppPreference) => {
      // map app preferences to settings
      const settings = mapAppPreferenceToSettings(appPref);

      // dispatch settings
      dispatchSettings(settings);
    },
    [dispatchSettings]
  );

  // receive fasterClient settings api response and dispatch it
  useEffect(() => {
    if (!pendingClientPrefs && clientPrefs?.appPreference) {
      //prevent further api requests
      setAppPrefApiArgs([null]);

      // dispatch result
      dispatchAppPreference(clientPrefs.appPreference);
    }
  }, [clientPrefs, pendingClientPrefs, dispatchAppPreference]);

  // build initial theme
  const { accent, direction } = settings;
  const appliedTheme = extendTheme({
    direction,
    ...getTokens(accent),
  });

  return !localStorageSettings &&
    (pendingClientPrefs || !_.values(settings)?.length) ? null : (
    <FwSettingsContext.Provider
      value={{
        settings,
        dispatchAppPreference,
        dispatchSettings,
      }}
    >
      <ChakraProvider theme={appliedTheme}>{children}</ChakraProvider>
    </FwSettingsContext.Provider>
  );
};

FwSettingsProvider.propTypes = fwSettingsProviderPT;

export { FwSettingsProvider };
