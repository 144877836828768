// import { AdminGridsButtons, AdminUsersButtons } from 'components';
import { Route } from 'core/model';
// type 'nav' (accessed from main nav)
import Account from 'scenes/dashboard/scenes/account/Account';
import Admin from 'scenes/dashboard/scenes/admin/Admin';
// import Help from 'scenes/dashboard/scenes/help/Help';
import Dev from 'scenes/dashboard/scenes/dev/Dev';

// buttons

const routes: Route[] = [
  {
    areable: true,
    path: '/admin',
    exact: false,
    component: Admin,
    key: 'Admin',
    name: 'Admin',
    design: { icon: 'RiSettings5Line' },
    // buttons: [
    //   { path: '/admin/grids', component: AdminGridsButtons },
    //   { path: '/admin/users', component: AdminUsersButtons },
    // ],
  },
  {
    areable: true,
    path: '/account',
    exact: false,
    component: Account,
    key: 'Account',
    name: 'Account',
    design: { icon: 'RiShieldUserFill' },
  },
  {
    path: '/dev',
    exact: false,
    component: Dev,
    key: 'Dev',
    name: 'Dev',
    design: { icon: 'RiCodeSSlashFill' },
  },
];

export default routes;
