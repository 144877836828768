import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { useApi } from 'api';
import templateFileApi from 'api/templateFile/templateFileApi';
import { FwLink, FwSegment, FwSpinner } from 'components/base';
import { Route, TemplateFile } from 'core/model';
import utils from 'core/utils/utils';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';
import { setRoutes } from 'scenes/dashboard/helpers/admin';

import { ROUTE_TYPES } from '../../routesAdmin';

const AdminFiles = () => {
  const apiArgsRef = useRef([]);
  const routesRef = useRef<Route[]>(
    setRoutes(undefined, ROUTE_TYPES.adminFiles, undefined)
  );

  const [files, setFiles] = useState<TemplateFile[]>();

  const { fetched, pending } = useApi(
    templateFileApi.getAll,
    apiArgsRef.current
  );

  useEffect(() => {
    if (!pending && fetched) {
      // sorted alphabetically
      const sortFiles = _.map(
        _.sortBy(fetched?.templateFiles, (i) => i.filename),
        (f) => new TemplateFile(f)
      );

      setFiles(sortFiles);
    }
  }, [fetched, pending]);

  return !pending && fetched ? (
    <>
      <TilesPanel scroll routes={routesRef.current} />
      <FwSegment padded>
        {files &&
          _.map(files, ({ filename, path }) => (
            <Box key={filename}>
              <FwLink onClick={() => utils.openInNewTab(path)}>
                {filename}
              </FwLink>
            </Box>
          ))}
      </FwSegment>
    </>
  ) : (
    <FwSpinner />
  );
};

export default AdminFiles;
