import _ from 'lodash';

import { executeScript } from 'components/form/components/template/helpers/executeScript';
import { Column, Design, RowDesign, TableData } from 'core/model';
import utils from 'core/utils/utils';

const applyDesign = (columns: Column[], tableData: TableData) => {
  const { rows } = tableData;

  // filter column has design
  const columnDesigns = _.filter(columns, (c) => c.design) as Column[];

  if (_.some(columnDesigns)) {
    _.forEach(rows, (r) => {
      const designs: RowDesign[] = [];

      // prepare data
      const fullData = utils.getFullRowData(r);

      _.forEach(columnDesigns, ({ key, design }) => {
        let rowDesign = design;

        // has script then evaluate
        if (design.script) {
          // prepare params
          const scriptParams = {
            docData: fullData,
            // not yet support store params
            store: {
              moduleStore: undefined,
              pageStore: undefined,
              pageContentStore: undefined,
            },
          };

          // evaluate
          rowDesign = executeScript(design.script.data, scriptParams) as Design;
        }

        designs.push({ key, design: rowDesign });
      });

      // assign to update dto value
      r.rowDesigns = designs;
    });
  }
};

export { applyDesign };
