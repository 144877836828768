import { dtoCtor } from '../helper';
import { Filter } from './filter';
import { Input } from './input';

class ExecutionFilter extends Filter {
  dtoName?: string;

  constructor(props?: Omit<ExecutionFilter, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'ExecutionFilter', props);
  }

  input?: Input;
  additionalData?: object;
}

export { ExecutionFilter };
