import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FwLabel } from 'components/base';
import { Route } from 'core/model';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import getListFilteredLower from './helpers/getListFilteredLower';

const Pages = ({
  pageRoutes,
  modules,
}: {
  pageRoutes: Route[];
  modules: Route[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      {pageRoutes &&
        _.map(
          _.filter(modules, (m) => !m.system),
          ({ name, design }, i) => (
            <div key={i} style={{ padding: '1rem' }}>
              <FwLabel basic leftIcon={design?.icon}>
                {t(`common|${name}`)}
              </FwLabel>
              <TilesPanel
                scroll
                small
                routes={getListFilteredLower(pageRoutes, 'type', name)}
              />
            </div>
          )
        )}
    </>
  );
};

export default Pages;
