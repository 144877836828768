import { useColorMode } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';

import { FwSettingsProviderProps } from 'core/model';

import { FwSettingsContext } from './FwSettingsContext';

const useFwSettings = () => {
  const { dispatchSettings, settings, ...rest } = useContext(FwSettingsContext);
  const { setColorMode } = useColorMode();

  const dispatchSettingsAndTheme = useCallback(
    (partialSettings: FwSettingsProviderProps) => {
      // setColorMode is passed here since it is defined in Chakra provider
      // which is FwSettingsProvider's child (FwSettingsProvider cannot access it)
      dispatchSettings(partialSettings, setColorMode);
    },
    [dispatchSettings, setColorMode]
  );

  return { ...rest, settings: settings || {}, dispatchSettingsAndTheme };
};

export { useFwSettings };
