import _ from 'lodash';
import { bool, string, InferProps } from 'prop-types';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import authenticationApi from 'api/authentication/authenticationApi';
import {
  FwField,
  FwGrid,
  FwImage,
  FwLabel,
  FwSegment,
  FwToast,
} from 'components/base';
import { Credentials2FA, Option } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import { CONFIG_2FA } from 'core/utils/constants';
import utils from 'core/utils/utils';

const { select, label, number } = FIELD_TYPE;

const Config2FA: FC<Props> = ({
  username,
  twoFactorEnabled: enable2faProp,
  authenticatorEnabled: enableAuthtorProp,
}) => {
  const { t } = useTranslation();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(enable2faProp);
  const [authtorEnabled, setAuthtorEnabled] = useState(enableAuthtorProp);
  const [configAuthtor, setConfigAuthtor] = useState<Credentials2FA>();
  const [options] = useState(
    _.map(
      [CONFIG_2FA.enabled, CONFIG_2FA.disabled],
      (n) =>
        new Option({
          key: n,
          value: n,
          text: t(n),
        })
    )
  );

  const set2fa = async (
    _e: ChangeEvent,
    { value }: { name: string; value: string | object; fillData?: object }
  ) => {
    const state = value === CONFIG_2FA.enabled;

    // updated value then send request
    if (state !== twoFactorEnabled) {
      setTwoFactorEnabled(state);

      const response = username
        ? await authenticationApi.enforce2fa(username, state)
        : await authenticationApi.set2fa(state);

      if (utils.isValidResponse(response)) {
        FwToast.success(
          `${t('2FA has been')} ${t(state ? 'enabled' : 'disabled')}`
        );
      }
    }
  };

  const setAuthenticator = async (
    _e: ChangeEvent,
    { value }: { name: string; value: string | object; fillData?: object }
  ) => {
    const state = value === CONFIG_2FA.enabled;

    // updated value then send request
    if (state !== authtorEnabled) {
      setAuthtorEnabled(state);

      if (state) {
        // request for new authenticator
        const setupInfo = await authenticationApi.setupAuthenticator();

        if (utils.isValidResponse(setupInfo)) {
          const {
            credentials: { twoFactorSetupKey, qrCodeImageData },
            succeeded,
          } = (setupInfo?.data || {}) as {
            credentials: Credentials2FA;
            succeeded: boolean;
          };

          if (succeeded) {
            // setup authenticator
            setConfigAuthtor({ twoFactorSetupKey, qrCodeImageData });
          }
        }
      } else {
        // remove authenticator
        const response = await authenticationApi.removeAuthenticator();

        if (utils.isValidResponse(response)) {
          FwToast.success(t(`Authenticator has been removed`));
        }
      }
    }
  };

  const registerAuthenticator = async (
    _e: ChangeEvent,
    { value }: { name: string; value: string | object; fillData?: object }
  ) => {
    const authCode = value as string;

    if (authCode.length === 6) {
      // filled code then trigger request validation
      const { twoFactorSetupKey } = configAuthtor;

      // send request
      const response = await authenticationApi.registerAuthenticator(
        twoFactorSetupKey,
        authCode
      );

      const { succeeded } = (response?.data || {}) as {
        credentials: Credentials2FA;
        succeeded: boolean;
      };

      if (succeeded) {
        // reset
        setConfigAuthtor(undefined);

        // toast message success
        FwToast.success(t(`Authenticator has been registered`));
      } else {
        // toast message invalid code
        FwToast.error(t(`Invalid code`));
      }
    }
  };

  const isEnabled = (enabled) =>
    enabled ? CONFIG_2FA.enabled : CONFIG_2FA.disabled;

  const { twoFactorSetupKey, qrCodeImageData } = configAuthtor || {};

  // todo #834 change these inputs to autosave input?
  return (
    <FwSegment
      as={FwGrid}
      leftIcon={'RiLockPasswordFill'}
      name={t('Two factor authentification')}
    >
      <FwField
        label={'2FA'}
        type={select}
        options={options}
        defaultValue={isEnabled(twoFactorEnabled)}
        onChange={set2fa}
      />
      {!username && (
        <FwField
          label={t('Authenticator')}
          type={select}
          options={options}
          disabled={!twoFactorEnabled}
          defaultValue={isEnabled(authtorEnabled)}
          onChange={setAuthenticator}
        />
      )}
      {twoFactorSetupKey && qrCodeImageData && !username && (
        <FwSegment
          as={FwGrid}
          leftIcon={'RiDoorLockLine'}
          name={t('Setup authenticator')}
        >
          <FwLabel backgroundColor={'transparent'}>QR Code</FwLabel>
          <FwImage src={qrCodeImageData} size={'150px'} />
          <FwField
            label={t('Setup key')}
            type={label}
            // insert separator to make reading easier
            defaultValue={utils.prettyAuthtorSetupKey(twoFactorSetupKey)}
          />
          <FwField
            label={t('Enter the code provided by your authenticator')}
            type={number}
            tooltip={t('6 digits')}
            pattern={'\\d{6}'}
            placeholder={'------'}
            onChange={registerAuthenticator}
          />
        </FwSegment>
      )}
    </FwSegment>
  );
};

const propTypes = {
  twoFactorEnabled: bool,
  authenticatorEnabled: bool,
  username: string,
};

export type Props = InferProps<typeof propTypes>;

Config2FA.propTypes = propTypes;

export default Config2FA;
