import { Identity, Area } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

// see FasterWeb\Startup.cs if there are any changes in pattern
const identity0AS: AdminStructure<Identity> = [
  {
    key: 'username',
    name: 'Username',
    required: true,
    isReadOnly: true,
    type: FIELD_TYPE.text,
    // pattern: '([\\w-._]*)*@[\\w-_]*\\.[\\w-._]*',
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'external',
    name: 'External user',
    type: FIELD_TYPE.checkbox,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
    additionalData: { noLabel: true },
  },
  {
    key: 'token',
    name: 'Password',
    required: true,
    type: FIELD_TYPE.password,
    pattern: '.{6,}',
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const identity1AS: AdminStructure<Identity> = [
  {
    key: 'roles',
    name: 'Roles',
    type: FIELD_TYPE.multiselect,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const identity2AS: AdminStructure<{ areas: Area[] }> = [
  {
    key: 'areas',
    name: 'User direct access areas',
    type: FIELD_TYPE.multiselect,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
    additionalData: {
      tooltip: 'User can also be granted area access via its roles',
    },
  },
];

const identity0Rules = [
  {
    event: {
      eventID: 1,
      action: 'ONCHANGE',
      type: 'FIELD',
      key: 'external',
    },
    script: {
      scriptID: 1,
      description: 'Show password field',
      action: 'SHOW',
      type: 'FIELD',
      data: "return { 'token': docData['external'] === 'true' ? null : true };",
    },
  },
  {
    event: {
      eventID: 1,
      action: 'ONCHANGE',
      type: 'FIELD',
      key: 'external',
    },
    script: {
      scriptID: 1,
      description: 'Empty password field',
      action: 'FILL',
      type: 'FIELD',
      data: "return { 'token': '' };",
    },
  },
];

const identity1Rules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'roles',
    },
    script: {
      scriptID: 1,
      description: 'Get options from assignation api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`assignation`).then(res => res && res.data.assignations && callback({ options: {'roles': res.data.assignations.map(a => ({ key: a.key, value: a.key, text: a.key, items: null })) } }))",
    },
  },
];

const identity2Rules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'areas',
    },
    script: {
      scriptID: 1,
      description: 'Get options from area api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`area`).then(res => res && res.data.areas && callback({ options: {'areas': res.data.areas.map(a => ({ key: a.key, value: a.key || 'main', text: a.key, items: null })) } }))",
    },
  },
];

export {
  identity0AS,
  identity1AS,
  identity2AS,
  identity0Rules,
  identity1Rules,
  identity2Rules,
};
