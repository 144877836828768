import { dtoCtor } from '../helper';
import { RowDesign } from './rowDesign';
import { Viewable } from './viewable';

class Row extends Viewable {
  dtoName?: string;

  constructor(props?: Omit<Row, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'Row', props);
  }

  rowID?: string;
  number: number;
  key?: string;
  type?: string;
  creator?: string;
  status?: string;
  formLayoutName?: string;
  data?: string;
  metaData?: object;
  from?: string;
  to?: string;
  rowDesigns?: RowDesign[];
}

export { Row };
