import ISO6391 from 'iso-639-1';
import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import languageApi from 'api/language/languageApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Language } from 'core/model';
import { createFilename } from 'core/utils/form/getValueChange';

import LanguageLayout from './LanguageLayout';

const LanguageLayoutContainer: FC = () => {
  const existingLanguage = useParams<{ name: string }>().name;

  const [getArgs] = useState([existingLanguage]);
  const { fetched: fetchedLanguage, pending: pendingLanguage } = useApi(
    existingLanguage ? languageApi.get : undefined,
    getArgs
  );

  const [language, setLanguage] = useState(
    existingLanguage
      ? undefined
      : new Language({
          key: '',
          languageContent: {
            batch: '',
            calendar: '',
            common: '',
            custom: '',
            glossary: '',
            settings: '',
            tracking: '',
          },
        })
  );

  const handleSetKey = (key: string) => {
    const newLanguage = { ...language, key: key };
    // set in state
    setLanguage(newLanguage);
  };

  const jsonStringImage = (file: string) =>
    JSON.stringify({ filename: createFilename(file), path: file });

  useEffect(() => {
    if (!pendingLanguage && fetchedLanguage) {
      // get native name with key
      fetchedLanguage.language.name = ISO6391.getNativeName(
        fetchedLanguage.language.key.toLowerCase()
      );

      const languageContent = fetchedLanguage.language.languageContent;
      _.forEach(languageContent, (value, key) => {
        if (value) {
          languageContent[key] = jsonStringImage(value);
        }
      });

      setLanguage(fetchedLanguage.language);
    }
  }, [fetchedLanguage, pendingLanguage]);

  // render
  return language ? (
    <LanguageLayout language={language} handleSetKey={handleSetKey} />
  ) : (
    <FwSpinner />
  );
};

export default LanguageLayoutContainer;
