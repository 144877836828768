import { Alert, Input, TypographyProps } from '@chakra-ui/react';
import { InferProps } from 'prop-types';
import React, { FC } from 'react';

import { useFwTheme } from 'components/base/utility';

import { CommonInputProps } from '../FwInput';

const FwLabelInput: FC<Props & CommonInputProps> = ({
  // common input props
  name,
  defaultValue,
  value,
}) => {
  const { _disabled } = useFwTheme();

  const defaultText = value || defaultValue;

  // todo refactor with input style?
  const labelInputstyle = {
    color: _disabled.color,
    backgroundColor: _disabled.bg,
    boxShadow: 'inset 0 0 0 1px rgb(34 36 38 / 22%), 0 0 0 0 transparent',
    borderRadius: '0.28571429rem',
    minHeight: 8,
    wordBreak: 'break-word' as TypographyProps['wordBreak'],
    py: 0,
  };

  return (
    <>
      <Input hidden data-name={name} />
      <Alert {...labelInputstyle}>{defaultText}</Alert>
    </>
  );
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwLabelInput.propTypes = propTypes;

export default FwLabelInput;
