import { Box, Input } from '@chakra-ui/react';
import { InferProps } from 'prop-types';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';

import { FwButton, FwImage, FwSegment } from 'components/base';
import { SIGN_EXPORT_FORMAT } from 'core/utils/constant';
import { createJsonString } from 'core/utils/form/getValueChange';
import utils from 'core/utils/utils';

import { CommonInputProps } from '../FwInput';

const FwSign: FC<Props & CommonInputProps> = ({
  // common input props
  defaultValue,
  name,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const signRef = useRef(null);
  const [path, setPath] = useState();

  const defaultSign = value || defaultValue;
  const isUpdatable = onChange || !defaultSign;

  useEffect(() => {
    // when component was unmounted then re-mounted
    if (signRef.current?.instance.isEmpty() && utils.tryParse(defaultSign)) {
      const jsValue = JSON.parse(defaultSign);

      // document data contains signature
      if (jsValue.data) {
        // redraw
        signRef.current?.instance.fromDataURL(jsValue.data);
      } else if (jsValue.path) {
        //
        setPath(jsValue.path);
      }
    }
  }, [defaultSign]);

  useEffect(() => {
    if (isUpdatable) {
      signRef.current?.on();
    } else {
      signRef.current?.off();
    }
  }, [isUpdatable]);

  const handleSignChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (onChange) {
      const dataUrl = signRef.current?.instance.toDataURL(
        SIGN_EXPORT_FORMAT.type
      );

      onChange(
        e,
        utils.getNameValueFromData({
          name,
          value: createJsonString(
            `${name}.${SIGN_EXPORT_FORMAT.extension}`,
            dataUrl
          ),
        })
      );
    }
  };

  const handleClear = () => {
    signRef.current?.clear();

    onChange?.(
      null,
      utils.getNameValueFromData({
        name,
        value: '',
      })
    );
  };

  const signOptions = {
    penColor: 'rgb(66, 133, 244)',
    onEnd: (e: ChangeEvent<HTMLInputElement>) => {
      handleSignChange(e);
    },
  };

  const handleOpenInNewTab = () => {
    utils.openInNewTab(path);
  };

  return !path ? (
    <FwSegment>
      <Box as={'fieldset'} position="relative" name={name}>
        <Box position="absolute">
          {defaultSign && (
            <FwButton small leftIcon={'RiCloseFill'} onClick={handleClear}>
              {t('common|Remove')}
            </FwButton>
          )}
        </Box>
        <Input hidden data-name={name} />
        <SignaturePad redrawOnResize ref={signRef} options={signOptions} />
      </Box>
    </FwSegment>
  ) : (
    <FwImage src={path} onClick={handleOpenInNewTab} />
  );
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwSign.propTypes = propTypes;

export default FwSign;
