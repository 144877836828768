import { Context, createContext } from 'react';

import { AppPreference, FwSettingsProviderProps } from 'core/model';

type FwSettingsProps = {
  settings: FwSettingsProviderProps;
  dispatchAppPreference?: (appPreference: AppPreference) => void;
  dispatchSettings?: (
    settings: FwSettingsProviderProps,
    setColorMode: (theme: string) => void
  ) => void;
};

const FwSettingsContext: Context<FwSettingsProps> = createContext({
  settings: {},
});

export { FwSettingsContext, FwSettingsProps };
