import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from 'api/useApi';
import userApi from 'api/user/userApi';
import userFilterApi from 'api/user/userFilterApi';
import { FwSpinner } from 'components/base';
import { UserFilter as UF } from 'core/model';

import UserFilter from './UserFilter';

const UserFilterContainer: FC = () => {
  const { name, fieldId } = useParams<{
    name: string;
    fieldId: string;
  }>();

  const argsUser = useRef([name]);
  const [argsUserFilter, setArgsUserFilter] = useState([]);
  const { fetched: fetchedFilter, pending: pendingFilter } = useApi(
    userFilterApi.get,
    argsUserFilter
  );

  const [userFilter, setUserFilter] = useState(undefined);
  const { fetched: fetchedUser, pending: pendingUser } = useApi(
    userApi.get,
    argsUser.current
  );

  // receive user from backend and set in state
  useEffect(() => {
    if (!pendingUser && fetchedUser) {
      if (fieldId) {
        setArgsUserFilter([fieldId, fetchedUser.appUser.id]);
      } else {
        setUserFilter(new UF({ userID: fetchedUser.appUser.id }));
      }
    }
  }, [fetchedUser, pendingUser, fieldId]);

  // receive userFilter from backend and set in state
  useEffect(() => {
    if (!pendingFilter && fetchedFilter) {
      setUserFilter(fetchedFilter.userFilter);
    }
  }, [fetchedFilter, pendingFilter]);

  const handleSetFieldID = (id: string) => {
    // set in state
    setUserFilter({ ...userFilter, fieldID: id });
  };

  // render
  return !pendingFilter && userFilter ? (
    <UserFilter userFilter={userFilter} handleSetKey={handleSetFieldID} />
  ) : (
    <FwSpinner />
  );
};

export default UserFilterContainer;
