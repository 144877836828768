import React from 'react';
import { Navigate } from 'react-router-dom';

import { useFwAuth } from 'components/base';
import Utils from 'core/utils/utils';

const AdminComponent = ({ render: Component }: any) => {
  const { user: currentUser } = useFwAuth();

  return Utils.canAccess(currentUser, 'admin') ? (
    <Component />
  ) : (
    <Navigate replace to="/" />
  );
};

export default AdminComponent;
