import _ from 'lodash';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AdminComponent } from 'components';
import { useFwArea } from 'components/base';
import utils from 'core/utils/utils';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import routesAdmin from './routesAdmin';

const Admin = () => {
  const { area, areaIsMain } = useFwArea();

  const subRoutes = _.map(
    _.filter(
      routesAdmin,
      (ra) => ra.type === 'admin' && (areaIsMain(area) || ra.areable)
    ),
    (r) => ({
      ...r,
      path: utils.buildUrlNav(area, null, r.path?.substring(1)),
    })
  );

  return (
    <Routes>
      <Route path={`/`} element={<TilesPanel routes={subRoutes} />} />
      {_.map(
        _.filter(routesAdmin, (ra) => areaIsMain(area) || ra.areable),
        ({ exact, path, relativePath, component: Component }, i) => {
          return (
            <Route
              key={path}
              path={`${relativePath}${!exact ? '/*' : ''}`}
              element={<AdminComponent key={i} render={() => <Component />} />}
            />
          );
        }
      )}
    </Routes>
  );
};

export default Admin;
