import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Area, Table, TableData } from 'core/model';
import { SYSTEM_FIELDS, OPERATOR, FIELD_TYPE } from 'core/utils/constant';
import {
  getQueryString,
  QueryCriteria,
} from 'core/utils/tableData/getQueryString';

const tableDataApi = {
  buildQuery: (tableKey: string, type: string, criteria: QueryCriteria) => {
    const baseUrlArray = ['tabledata'];

    if (type) {
      baseUrlArray.push(type);
    }

    const query = getQueryString(criteria);

    return `${baseUrlArray.join('/')}/${tableKey || ''}${query}`;
  },
  getMany: (
    query: string,
    ids: string[],
    table: Table,
    dataType: string,
    dataKey: string,
    area: Area,
    documentID: string,
    params: BaseApiParams
  ) => {
    if (ids && ids.length > 0) {
      const filter = {
        key: SYSTEM_FIELDS.formid,
        operator: OPERATOR.in,
        value: ids.join('|'),
        type: FIELD_TYPE.number,
      };

      // post id filter
      return BaseApi.post<unknown, { tableData: TableData }>(query, {
        filter,
        ...params,
      });
    } else if (dataType) {
      // post table structure of admin to query
      return BaseApi.post<unknown, { tableData: TableData }>(query, {
        dataType,
        dataKey,
        area: area.key,
        table,
        ...params,
      });
    } else {
      if (documentID) {
        query = `${query}&documentID=${documentID}`;
      }

      // get table data
      return BaseApi.get<{ tableData: TableData }>(query, params);
    }
  },
};

export default tableDataApi;
