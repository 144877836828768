import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import inputApi from 'api/input/inputApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Input as I } from 'core/model';

import Input from './Input';

const InputContainer: FC = () => {
  const { inputId, pageContentId } = useParams<{
    inputId: string;
    pageContentId: string;
  }>();

  const id = inputId == 'newInput' ? undefined : inputId;

  const argsInput = useRef(id ? [inputId] : []);

  const [input, setInput] = useState<I>(
    id ? undefined : new I({ pageContentID: pageContentId, row: 0, column: 0 })
  );

  const { fetched: fetchedInput, pending: pendingInput } = useApi(
    inputApi.getById,
    argsInput.current
  );

  // receive input from backend and set in state
  useEffect(() => {
    if (!pendingInput && fetchedInput) {
      setInput(fetchedInput.input);
    }
  }, [fetchedInput, pendingInput]);

  const handleSetID = (id: string) => {
    // set in state
    setInput({ ...input, fieldID: id });
  };

  // render
  return !pendingInput && input ? (
    <Input input={input} handleSetKey={handleSetID} />
  ) : (
    <FwSpinner />
  );
};

export default InputContainer;
