import React from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeSelector, useInputFormContainer } from 'components';
import {
  FwButton,
  FwField,
  FwGrid,
  FwParagraph,
  FwSegment,
  FwSpinner,
  useFwSettings,
} from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';

const { checkbox } = FIELD_TYPE;

const Settings = ({
  eraseTableCache,
  eraseTemplatesCache,
  loadingCache,
  tableCache,
  templatesCache,
}: any) => {
  const { t } = useTranslation();
  const {
    settings: { isProd },
    dispatchSettingsAndTheme,
  } = useFwSettings();

  const toggleUnreleasdFeatures = (field, value) => {
    // todo wip#585 refactor (see preferenceContainer.js)
    dispatchSettingsAndTheme({ isProd: value === 'true' });
  };

  const configInputs = [
    {
      key: 'Hide unreleased features',
      name: 'Hide unreleased features',
      label: t('settings|Hide unreleased features'),
      type: checkbox,
      row: 1,
      column: 1,
      additionalData: { noLabel: true },
      subInputs: [],
      visible: true,
    },
  ];

  const { fields } = useInputFormContainer(
    false,
    undefined,
    { 'Hide unreleased features': `${isProd}` },
    configInputs,
    toggleUnreleasdFeatures
  );

  return (
    <FwGrid>
      <FwSegment as={FwGrid} leftIcon="RiToggleFill" name="Configure">
        <FwGrid items={fields} itemComponent={FwField} />
      </FwSegment>
      <ThemeSelector />
      <FwSegment as={FwGrid} leftIcon="RiSdCardMiniFill" name="Cache">
        <FwGrid responsive>
          <FwGrid.Item>
            <FwParagraph heading>{t('settings|Table cache')}</FwParagraph>
            {loadingCache ? (
              <FwSpinner />
            ) : (
              <FwParagraph>{tableCache}</FwParagraph>
            )}
            <FwButton
              fluid
              small
              leftIcon="RiEraserFill"
              onClick={eraseTableCache}
            >
              {t('settings|Erase table cache')}
            </FwButton>
          </FwGrid.Item>
          <FwGrid.Item>
            <FwParagraph heading>{t('settings|Templates cache')}</FwParagraph>
            {loadingCache ? (
              <FwSpinner />
            ) : (
              <FwParagraph>{templatesCache}</FwParagraph>
            )}
            <FwButton
              fluid
              small
              leftIcon="RiEraserFill"
              onClick={eraseTemplatesCache}
            >
              {t('settings|Erase templates cache')}
            </FwButton>
          </FwGrid.Item>
        </FwGrid>
      </FwSegment>
    </FwGrid>
  );
};

export default Settings;
