import BaseApi, { BaseApiParams } from 'api/baseApi';
import { TemplateFile } from 'core/model';

const templateFileApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ templateFiles: TemplateFile[] }>(`templateFile`, params),
  post: (templateFile: TemplateFile, params: BaseApiParams) =>
    BaseApi.post(`templateFile`, templateFile, params),
};

export default templateFileApi;
