import React, { FC, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { PrivateComponent, ShareComponent /*, FasterMap*/ } from 'components';
import { FwSpinner, useFwArea } from 'components/base';
import { areaRoutesPT, AreaRoutesProps } from 'core/model';

const Dashboard = lazy(() => import('./scenes/dashboard/dashboardContainer'));
const Public = lazy(() => import('./scenes/public/Public'));
const Zoom = lazy(() => import('./scenes/public/components/zoomContainer'));
const FilterDoc = lazy(() => import('./components/doc/FilterDoc'));
const PublicDoc = lazy(() => import('./components/doc/PublicDoc'));

const AreaRoutes: FC<AreaRoutesProps> = ({ areaKey, basePath }) => {
  const { area, setAreaKey } = useFwArea();

  useEffect(() => {
    setAreaKey(areaKey);
  }, [areaKey, setAreaKey]);

  return !area ? (
    <FwSpinner />
  ) : (
    <Routes>
      {/* share routes */}
      <Route
        path={`public/:module/:id`}
        element={
          <ShareComponent>
            <PublicDoc />
          </ShareComponent>
        }
        //   // todo maybe generalize ShareRoute to use for different routes
        //   // and create 'share/' urls (instead of 'public/' here) for them
      />
      {/* public routes */}
      <Route
        path={`public/*`}
        element={<Public loginContent={area.areaContent} />}
      />
      {/* private routes */}
      {/* <PrivateRoute
        exact
        path={`${basePath}dispatch/map`}  
        component={FasterMap}
      /> */}
      <Route
        path={`filterselection`}
        element={
          <PrivateComponent path={`${basePath}`}>
            <FilterDoc />
          </PrivateComponent>
        }
      />
      <Route
        path={`zoom/:module/:id`}
        element={
          <PrivateComponent path={`${basePath}`}>
            <Zoom />
          </PrivateComponent>
        }
      />
      <Route
        path={`/*`}
        element={
          <PrivateComponent path={basePath}>
            <Dashboard />
          </PrivateComponent>
        }
      />
    </Routes>
  );
};

AreaRoutes.propTypes = areaRoutesPT;

export default AreaRoutes;
