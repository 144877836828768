import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { pushUrl } from 'scenes/dashboard/helpers/admin';

import AdminUsers from './AdminUsers';

const AdminUsersContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AdminUsers handleAddRow={() => pushUrl(navigate, location, 'register')} />
  );
};

export default AdminUsersContainer;
