import { dtoCtor, Dto } from '../helper';
import { EvalValue } from './evalValue';
import { ExecutionFilter } from './executionFilter';
import { Modal } from './modal';
import { Rule } from './rule';
import { Script } from './script';

class Execution implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Execution, 'dtoName'>) {
    dtoCtor(this, 'Execution', props);
  }

  duplicate?: boolean;
  step: number;
  additionalData?: object;
  filters?: ExecutionFilter[];
  edits?: EvalValue[];
  script?: Script;
  rules?: Rule[];
  modals?: Modal[];
}

export { Execution };
