import _ from 'lodash';
import { Location, NavigateFunction } from 'react-router-dom';

import { Design, Table } from 'core/model';
import utils from 'core/utils/utils';
import routesAdmin from 'scenes/dashboard/scenes/admin/routesAdmin';

export const pushUrl = (
  navigate: NavigateFunction,
  location: Location,
  info?: string,
  state?: unknown
) => {
  navigate(utils.buildUrlNavFromPath(location.pathname, info || ''), state);
};

export const setRoutes = (
  dtos: { design?: Design; key?: string; name?: string }[],
  routeType: string,
  keyPath: string,
  useName = false,
  defaultIcon?: string
) => {
  const routes = routesAdmin.filter((r) => r.type === routeType).slice();
  const { buttons, component, design, exact, name, path, type } = routes[0];

  if (dtos) {
    _.map(dtos, (dto) =>
      routes.push({
        path: path.replace(keyPath, dto.key),
        exact,
        type,
        component,
        name: useName ? dto.name : dto.key,
        design: {
          icon: dto.design?.icon ? dto.design?.icon : defaultIcon,
        },
        buttons,
      })
    );
  }

  routes[0] = {
    path: path.replace('/' + keyPath, ''),
    exact,
    type,
    component,
    name: `New ${name.toLowerCase()}`,
    design: { icon: design?.icon },
    buttons,
  };

  return routes;
};

export const setTableStruct = (
  key: string,
  id: string,
  tableStructure: Table
) => {
  let tableStruct = undefined;

  if (id) {
    const updatedCols = _.filter(tableStructure.columns, { key: key });
    const otherCols = _.filter(tableStructure.columns, (c) => c.key !== key);

    tableStruct = {
      ...tableStructure,
      columns: [...updatedCols.map((c) => ({ ...c, value: id })), ...otherCols],
    };
  }

  return tableStruct;
};
