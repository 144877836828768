import { TFunction } from 'i18next';
import _ from 'lodash';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FwStore } from 'components/base/utility';
import { executeScript } from 'components/form/components/template/helpers/executeScript';
import { defaultAccent, fwAccent } from 'config/theme/constants';
import {
  BatchTemplate,
  Column,
  FwItemProps,
  Row,
  RowDesign,
  TableData,
} from 'core/model';
import { FwMaskCommonProps, FwMaskProps } from 'core/model/props/FwMask.props';
import { CONTENT_TYPE, BUTTON_TYPE } from 'core/utils/constant';
import {
  dateFormats,
  getDateOrDefault,
  jsDateFromString,
} from 'core/utils/date';
import utils from 'core/utils/utils';

import {
  MaskColors,
  MaskStructure,
  TextObject,
  TextTypes,
} from './FwMask.structures';
import { MaskRow } from './FwMask.types';

import { Agenda, Cards, Map, Timeline } from '.';

const { agenda, cards, map, timeline } = CONTENT_TYPE;

const iconByType = {
  [agenda]: 'RiCalendarFill',
  [cards]: 'RiMenuFill',
  [map]: 'RiMapFill',
  [timeline]: 'RiTimeFill',
};

const textByType = {
  [agenda]: 'agenda',
  [cards]: 'cards',
  [map]: 'map',
  [timeline]: 'timeline',
};

const getContainerStyle = ({ height, type, zoomed }: Partial<FwMaskProps>) => {
  let minH: string = undefined;
  let h: string = undefined;
  let overflow: string = undefined;

  if (zoomed) {
    minH = 'calc(100vh - 65px)';

    switch (type) {
      case agenda:
      case map:
        h = minH;
        break;
    }
  } else if (height) {
    h = `${height}px`;
  } else {
    switch (type) {
      case agenda:
        h = '650px';
        break;
      case map:
        h = '500px';
        break;
    }
  }

  if ((zoomed || height) && !_.includes([map], type)) {
    overflow = 'auto';
  }

  return { minH, h, overflow };
};

const getTableHeigth = (height: number, type: string) => {
  // agenda, cards and timeline have 80px difference with table
  // map have 86px difference with table
  return height ? (type == map ? height - 86 : height - 80) : undefined;
};

const getMaskComponent = (type: string) => {
  let component: FC<FwMaskCommonProps>;

  switch (type) {
    case agenda:
      component = Agenda;
      break;
    case cards:
      component = Cards;
      break;
    case map:
      component = Map;
      break;
    case timeline:
      component = Timeline;
      break;
    default:
      component = Fragment;
      break;
  }

  return component;
};

// todo wip#125 refactor
// retrieve and format cell data by row and column
const getCellData = (row: Row, columnKey: string | number) => {
  let cellData = '';

  // if string then parse and update property
  if (row.data && typeof row.data === 'string') {
    row.data = JSON.parse(row.data);
  }

  // prepare data
  const fullData = utils.getFullRowData(row);

  if (fullData.hasOwnProperty(columnKey)) {
    // extract column data
    cellData = _.isNil(fullData[columnKey])
      ? ''
      : fullData[columnKey].toString();
  }

  return cellData;
};

// todo wip#125 refactor
// extract and filter row data by columns
const getRowData = (row: Row, columns: Column[]) => {
  const data = {};

  // get data for each cell in row
  _.forEach(columns, (c) => {
    data[c.key] = getCellData(row, c.key);
  });

  return data;
};

const getColor = (
  rowDesigns: RowDesign[],
  colors: MaskColors,
  colorType: keyof MaskColors
) => {
  const colorKeys = colors?.[colorType];
  const evaluationKeys = _.map(rowDesigns, (design) => design.key);
  const intersectionKeys = _.intersection(colorKeys, evaluationKeys);

  const color = intersectionKeys?.length
    ? /* the value at position 0 of intersectionKeys is the priority key to select the color */
      _.find(rowDesigns, { key: intersectionKeys[0] })?.design[colorType]
    : /* when intersectionKeys is not defined, uses by default the values of evaluationDesigns[0] */
      rowDesigns?.[0]?.design[colorType];

  return color;
};

// todo wip#125 refactor
const getMaskRowStyle = (row: Row, maskType: string, colors: MaskColors) => {
  const { rowDesigns } = row;

  return {
    backgroundColor:
      getColor(rowDesigns, colors, 'backgroundColor') ??
      // for now, FasterWeb map are always in light theme mode
      // light accent will then be used in all cases as markers background color
      (maskType === map
        ? defaultAccent
        : maskType === cards
        ? undefined
        : `var(--chakra-colors-${fwAccent})`),
    borderColor:
      getColor(rowDesigns, colors, 'borderColor') ??
      (maskType === cards ? `var(--chakra-colors-${fwAccent})` : undefined),
    color: getColor(rowDesigns, colors, 'color') ?? undefined,
  };
};

// todo wip#125 refactor
const getMaskRow = (
  columns: Column[],
  row: Row,
  maskType: string,
  colors: MaskColors
): MaskRow => {
  // read data from row and columns
  const data = getRowData(row, columns);

  // read style from row cell designs
  const rowStyle = getMaskRowStyle(row, maskType, colors);

  // return masked row
  const maskRow = {
    ...row,
    ...rowStyle,
    data,
  };

  return maskRow;
};

// todo wip#125 refactor
const getMaskRows = (
  data: TableData,
  columns: Column[],
  maskType: string,
  colors: MaskColors
) => {
  const { rows } = data || {};
  return _.map(rows, (r) => getMaskRow(columns, r, maskType, colors));
};

// todo wip#125 refactor
const getMaskRowText = (
  maskStructure: MaskStructure,
  rowData: Record<string, string>,
  t: TFunction
) => {
  const {
    document: { text: textMatrix },
  } = maskStructure;

  const textValues = textMatrix
    ? _.map(textMatrix, (textArray: string[] | TextObject[]) =>
        _.map(textArray, (textEntry: string | TextObject) =>
          typeof textEntry === 'string'
            ? /* textEntry is a field key */
              rowData[textEntry]
            : /* textEntry is a TextObject */
            textEntry?.type === TextTypes.fieldKey
            ? rowData[textEntry?.text]
            : t(`custom|${textEntry?.text}`)
        ).join(' ')
      )
    : _.values(rowData);

  return _.compact(textValues).join('\n');
};

const getViewDate = (date: string | TextObject, store: FwStore): Date => {
  const dateValue = date
    ? typeof date === 'string'
      ? /* date is a field key */
        getDateOrDefault(jsDateFromString(date, dateFormats.isoDate))
      : /* date is a DateObject */
      date.type === TextTypes.script
      ? /* text is a script */
        executeScript(date.text, { store })
      : /* text is static */ getDateOrDefault(
          jsDateFromString(date.text, dateFormats.isoDate)
        )
    : new Date();

  return dateValue;
};

const getContextMenuItems = (
  t,
  openFunc,
  openInNewTabFunc,
  processes: BatchTemplate[],
  onProcessClick
): FwItemProps[] => {
  const items: FwItemProps[] = [];

  openFunc &&
    items.push(
      new FwItemProps({
        itemKey: 'Open',
        text: t('common|Open'),
        onClick: openFunc,
      })
    );

  openInNewTabFunc &&
    items.push(
      new FwItemProps({
        itemKey: 'Open in new tab',
        text: t('common|Open in new tab'),
        leftIcon: 'RiExternalLinkLine',
        onClick: openInNewTabFunc,
      })
    );

  processes &&
    onProcessClick &&
    items.push(
      ...processes
        // keep only process button type edit
        .filter(({ process: { type } }) => [BUTTON_TYPE.edit].includes(type))
        .map(({ batchTemplateId, process: { name, design } }) => {
          const { icon, iconColor, color } = design || {};

          return new FwItemProps({
            color: utils.getColor(color),
            itemKey: name,
            leftIcon: icon,
            leftIconColor: utils.getColor(iconColor),
            text: t(`custom|${name}`),
            onClick: () => onProcessClick(batchTemplateId),
          });
        })
    );

  return items;
};

const useHeaderFooter = ({
  headerFooter,
  table,
  mapCol,
  store,
  defaultValue = '',
}) => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      headerFooter
        ? executeScript(headerFooter.script, {
            table,
            field: mapCol,
            store,
            t,
          })
        : t(`custom|${defaultValue}`),
    [table, mapCol, t, headerFooter, defaultValue, store]
  );
};

export {
  iconByType,
  textByType,
  getCellData,
  getContainerStyle,
  getTableHeigth,
  getMaskComponent,
  getMaskRows,
  getMaskRowText,
  getRowData,
  getContextMenuItems,
  getViewDate,
  useHeaderFooter,
};
