import { AxiosResponse } from 'axios';

import baseApi, { BaseApiParams } from 'api/baseApi';
import {
  AutoSaveInfo,
  Credentials,
  Credentials2FA,
  Identity,
} from 'core/model';

const commonParams = { validErrors: [400, 401, 403, 404, 500] };

const common = async <B extends true | false>(
  username: string,
  password: string,
  initialize: B
): Promise<AxiosResponse | AxiosResponse<{ identity: Identity }>> => {
  const credentials = { username, password };

  return initialize
    ? await baseApi.put(
        `authentication/initialize`,
        { credentials },
        commonParams
      )
    : await baseApi.post<{ credentials: Credentials }, { identity: Identity }>(
        `authentication/login`,
        { credentials },
        commonParams
      );
};

const common2FA = async (
  method: string,
  username?: string,
  token?: string,
  rememberMe?: boolean
): Promise<AxiosResponse | AxiosResponse<{ identity: Identity }>> => {
  const credentials2FA = { username, token, method, rememberMe };

  return token
    ? await baseApi.post<
        { credentials: Credentials2FA },
        { identity: Identity }
      >(
        `authentication/login/2fa`,
        { credentials: credentials2FA },
        commonParams
      )
    : await baseApi.post(`authentication/otp`, { method }, commonParams);
};

const authenticationApi = {
  initialize: async (
    username: string,
    password: string
  ): Promise<AxiosResponse> => {
    return common(username, password, true);
  },
  login: async (
    username: string,
    password: string
  ): Promise<AxiosResponse<{ identity: Identity }>> => {
    return await common(username, password, false);
  },
  login2FA: async (
    username: string,
    token: string,
    method: string,
    rememberMe: boolean
  ): Promise<AxiosResponse<{ identity: Identity }>> => {
    return await common2FA(method, username, token, rememberMe);
  },
  sendOtp: async (method: string): Promise<AxiosResponse> => {
    return common2FA(method);
  },
  sendPasswordResetToken: async (
    username: string,
    resetPasswordUrl: string
  ): Promise<AxiosResponse> => {
    return await baseApi.post(
      `authentication/otp`,
      { username, resetPasswordUrl },
      { validErrors: [401, 404] }
    );
  },
  signup: async (identity: Identity) => {
    return await baseApi.post(`authentication/register`, { identity });
  },
  resetPassword: async (
    tokenReset: string,
    username: string,
    newPassword: string
  ) => {
    return await baseApi.put(
      `authentication/resetpassword`,
      {
        username,
        newPassword,
        tokenReset,
      },
      { validErrors: [400, 401, 404] }
    );
  },
  set2fa: async (enable: boolean) => {
    return await baseApi.put(`authentication/set2fa`, { enable });
  },
  enforce2fa: async (username: string, enable: boolean) => {
    return await baseApi.put(`authentication/enforce2fa`, { username, enable });
  },
  setupAuthenticator: async () =>
    await baseApi.get(`authentication/setupauthenticator`),
  registerAuthenticator: async (setupCode: string, authCode: string) =>
    await baseApi.post(
      `authentication/registerauthenticator`,
      {
        authCode,
        setupCode,
      },
      {
        validErrors: [401],
      }
    ),
  removeAuthenticator: async () =>
    await baseApi.delete(`authentication/removeauthenticator`),
  post: (identity: Identity, params: BaseApiParams) =>
    baseApi.post(`authentication/register`, identity, params),
  put: (identity: Identity, params: BaseApiParams) =>
    baseApi.put(`authentication`, identity, { ...params, validErrors: [401] }),
  delete: (username: string) => baseApi.delete(`authentication/${username}`),
  putAS: (
    autoSaveInfo: AutoSaveInfo<Identity, string>,
    params: BaseApiParams
  ) => baseApi.put('authentication/AS', { autoSaveInfo }, params),
};

export default authenticationApi;
