import _ from 'lodash';

import { AppUser, Area, Module, Route } from 'core/model';
import Utils from 'core/utils/utils';

const getModulesAccessible = (
  moduleRoutes: (Module & Route)[],
  area: Area,
  user: AppUser,
  isAll: boolean
) =>
  _.filter(
    moduleRoutes,
    ({ areaOnly, areas, key, system }) =>
      !system &&
      (isAll || Utils.canAccess(user, key)) &&
      (isAll || Utils.showInArea({ areaOnly, areas }, area))
  ).map((m) => ({ ...m, path: `/${m.key.toLowerCase()}` }));

export default getModulesAccessible;
