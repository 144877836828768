import { Grid, GridItem } from '@chakra-ui/react';
import _ from 'lodash';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FwCard, FwContextMenu, FwInput, FwSpinner } from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import { FIELD_TYPE } from 'core/utils/constant';

import { Card, filterCards, formToCard } from './FwMask.Cards.helpers';

const FwCards: FC<FwMaskCommonProps> = ({
  maskStructure,
  maskRows,
  loading,
  processes,
  // functions
  handleOpen,
  handleProcessActionClick,
}) => {
  const { t } = useTranslation();
  const { view } = maskStructure;

  const [cards, setCards] = useState<Card[]>(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (maskStructure && maskRows) {
      setCards(
        filterCards(
          _.map(maskRows, (mr) => formToCard(maskStructure, mr, t)),
          searchText
        )
      );
    }
  }, [maskStructure, maskRows, searchText, t]);

  return (
    <>
      {!view?.hideSearch && (
        <Grid alignItems="center" templateColumns="repeat(3, 1fr)">
          <GridItem>
            <FwInput
              search
              placeholder={t('common|Search...')}
              rightIcon={'RiSearchLine'}
              value={searchText}
              onChange={(
                _e: ChangeEvent,
                { value }: { name: string; value: string; fillData?: object }
              ) => setSearchText(value)}
              type={FIELD_TYPE.text}
              mb={2}
            />
          </GridItem>
        </Grid>
      )}
      <Grid>
        {!loading && cards ? (
          _.map(
            cards,
            ({ key, title, backgroundColor, borderColor, color }, index) => {
              const id = key || index;
              const openFunc = () => handleOpen(id);

              // define context menu items
              const contextItems = getContextMenuItems(
                t,
                openFunc,
                () => handleOpen(id, true),
                processes,
                (processId: string) => handleProcessActionClick(processId, id)
              );

              return (
                <GridItem key={id} my={2}>
                  <FwContextMenu
                    renderTag="div"
                    childrenRenderTag="div"
                    items={contextItems}
                  >
                    <FwCard
                      backgroundColor={backgroundColor}
                      borderColor={borderColor}
                      color={color}
                      content={_.map(title.split('\n'), (s) => [s])}
                      searchText={searchText}
                      onClick={openFunc}
                    />
                  </FwContextMenu>
                </GridItem>
              );
            }
          )
        ) : (
          <FwSpinner />
        )}
      </Grid>
    </>
  );
};

FwCards.propTypes = {};

export default FwCards;
