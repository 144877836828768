import _ from 'lodash';

import { ACTION, CONTENT_TYPE } from 'core/utils/constant';
import { getModalOptions } from 'core/utils/utilsModal';

import { applyScript } from '../../form/components/template/helpers/executeScript';

const { focusOut, onChange, show } = ACTION;
const { field, popup } = CONTENT_TYPE;

const ruleHandler = (modal: any, modals) => (rule, docData, value, key) => {
  // find popup by key
  const popup = _.find(modals, { key });

  if (popup && value) {
    modal.isOpen = true;
    modal.options = getModalOptions({ ...popup, docData });
  }
};

const showModal = (
  doc,
  docData,
  updatedKeys,
  isBlurEvent,
  store: {
    moduleStore: unknown;
    pageStore: unknown;
    pageContentStore: unknown;
  },
  indexItemChange?
) => {
  const modal: any = {};
  const { rules, modals } = doc.template;

  if (updatedKeys) {
    const eventAction = isBlurEvent ? focusOut : onChange;

    const triggeredRules = rules.filter(
      (r) =>
        // target
        r.script.action === show &&
        r.script.type === popup &&
        // trigger
        r.event.action === eventAction &&
        r.event.type === field &&
        _.includes(updatedKeys, r.event.key)
    );

    applyScript(triggeredRules, {
      doc,
      data: docData,
      handler: ruleHandler(modal, modals),
      indexItemChange,
      store,
    });
  }

  return modal.options ? modal : undefined;
};

export default showModal;
