import { TFunction } from 'i18next';
import _ from 'lodash';

import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure } from '../FwMask.structures';
import { MaskRow } from '../FwMask.types';

type Card = {
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  key?: string;
  row?: object;
  title?: string;
};

// todo wip#125 refactor
const formToCard = (
  maskStructure: MaskStructure,
  maskRow: MaskRow,
  t: TFunction
): Card => {
  const { key, data: rowData, backgroundColor, borderColor, color } = maskRow;
  const title = getMaskRowText(maskStructure, rowData, t);

  return {
    title,
    key,
    backgroundColor,
    borderColor,
    color,
    row: maskRow,
  };
};

const filterCards = (cards: Card[], searchText: string) =>
  cards && searchText
    ? _.filter(cards, (card) =>
        _.includes(card.title.toUpperCase(), searchText.toUpperCase())
      )
    : cards;

export { Card, filterCards, formToCard };
