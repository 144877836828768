import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import pageContentApi from 'api/page-content/pageContentApi';
import pageApi from 'api/page/pageApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Page } from 'core/model';
import { pushUrl, setTableStruct } from 'scenes/dashboard/helpers/admin';

import tableStructure from '../../helpers/tableStructure';
import PageEdit from './PageEdit';

const PageEditContainer: FC = () => {
  const {
    moduleKey: module,
    templateKey: template,
    pageKey,
  } = useParams<{ moduleKey: string; templateKey: string; pageKey: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const key = pageKey == 'newPage' ? '' : pageKey;

  const args = useRef(key ? [key] : []);

  const [argsDelete, setArgsDelete] = useState([]);
  // const [deleteRow, setDeleteRow] = useState<number>();
  const [currentKey, setCurrentKey] = useState(key);
  const [page, setPage] = useState(
    key ? undefined : new Page({ module, template, position: -1 })
  );

  const { fetched, pending } = useApi(pageApi.get, args.current);
  const {
    //pending: pendingDelete, response: responseDelete
  } = useApi(pageContentApi.delete, argsDelete);

  // receive page from backend and set in state
  useEffect(() => {
    if (!pending && fetched) {
      setPage(fetched.page);
    }
  }, [fetched, pending]);

  // // update page after delete pageContent
  // useEffect(() => {
  //   if (!pendingDelete && responseDelete) {
  //     page.pageContent.splice(deleteRow, 1);
  //   }
  // }, [pendingDelete, responseDelete]);

  const handleSetID = (id) => {
    // set in state
    setPage({ ...page, pageID: id });

    // change url when a new page was create
    pageKey == 'newPage'
      ? navigate(location.pathname.replace('newPage', currentKey))
      : pushUrl(navigate, location, currentKey);
  };

  const handleEntityChange = (data) => {
    data?.key && setCurrentKey(data?.key);
  };

  const handleDeleteRow = (_rowNumber: number, pageContentId: string) => {
    // delete pageContent
    setArgsDelete([page.pageID, pageContentId]);
    // setDeleteRow(rowNumber);
  };

  // set filter on page contents table
  const tableStruct = setTableStruct('ViewID', page?.pageID, tableStructure);

  const pageProps = {
    page,
    tableStruct,
    handleAddRow: () =>
      pushUrl(navigate, location, 'newPageContent', { pageId: page.pageID }),
    handleDeleteRow,
    handleSetKey: handleSetID,
    handleEntityChange,
  };

  // render
  return !pending && page ? <PageEdit {...pageProps} /> : <FwSpinner />;
};

export default PageEditContainer;
