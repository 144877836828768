import React, { FC } from 'react';
import { Navigate, RouteProps /*, useLocation*/ } from 'react-router-dom';

//import { LocationStateProps } from 'config/router';

const RedirectNotFound: FC<RouteProps> = () => {
  // todo: creation of a new redirection with location object and an "is404" error.
  // maybe remember which URL we tried to navigate to so that it can potentially be reused.

  //const location = useLocation();
  //const state: LocationStateProps = { is404: true };
  //return <Navigate replace to={Object.assign({}, location, { state })} />;

  return <Navigate replace to={'/'} />;
};

export default RedirectNotFound;
