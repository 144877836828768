import React, { FC, useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

import {
  FwModuleProvider,
  FwPageProvider,
  FwTemplatesProvider,
  useFwAuth,
} from 'components/base';
import { getToken } from 'core/utils/storage';

const PrivateComponent: FC<RouteProps> = ({ path, children }) => {
  const token = getToken();
  const { user, checkUserAccess } = useFwAuth();

  if (user && token) {
    checkUserAccess();
  }

  useEffect(() => {
    const fetchCustomHtml = async () => {
      try {
        const response = await fetch('/custom.html');

        // if custom.html file exists
        if (response.ok) {
          // read content
          const htmlContent = await response.text();

          // insert content into a temporary div element
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = htmlContent;

          // extract nodes from this temporary div
          const nodes = tempDiv.childNodes;

          // append each node to the head tag
          nodes.forEach((node) => {
            document.head.appendChild(node);
          });
        }
      } catch (error) {}
    };

    fetchCustomHtml();
  }, []);

  return user && token ? (
    <FwTemplatesProvider>
      <FwModuleProvider>
        <FwPageProvider>{children}</FwPageProvider>
      </FwModuleProvider>
    </FwTemplatesProvider>
  ) : (
    <Navigate replace to={`${path}public/login`} />
  );
};

export default PrivateComponent;
