import { chakra, Grid, GridItem, Skeleton } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, UseTableInstanceProps } from 'react-table';

import {
  FwButton,
  FwLink,
  FwMenu,
  FwParagraph,
  FwSegment,
  FwTable,
} from 'components/base';
import FwData from 'components/base/elements/data/FwData';
import { FwTableColumnProps } from 'components/base/elements/table/FwTable';
import { useFwStore } from 'components/base/utility/moduleStore/useFwStore';
import { Column } from 'core/model';
import {
  FwMaskCommonProps,
  FwMaskProps,
  fwMaskPT,
} from 'core/model/props/FwMask.props';

import {
  iconByType,
  textByType,
  getMaskComponent,
  getRowData,
  getContainerStyle,
  getTableHeigth,
  useHeaderFooter,
  getMaskRows,
} from './FwMask.helpers';

const FwMask: FC<FwMaskProps> = ({
  data,
  dataType,
  height,
  loading,
  maskData,
  menuItems,
  type,
  table,
  style,
  zoomed,
  ...props
}) => {
  const { t } = useTranslation();
  const store = useFwStore();

  const { additionalData, columns /*, design*/ } = table || {};
  const {
    collapsed,
    compact,
    disableViewed,
    disableDoubleClick,
    disableContext,
    footers,
    headers,
    responsive,
    showSubFieldData,
    wrap,
  } = additionalData || {};
  const { currentPage, rows, totalPage, totalRow, rowPerPage, allowSearchAll } =
    data || {
      currentPage: 0,
      totalPage: 0,
      totalRow: 0,
      allowSearchAll: true,
    };

  // const tableDesign = {
  //   color: design ? utils.getColor(design.color) : null,
  //   backgroundColor: design ? utils.getColor(design.backgroundColor) : null,
  //   borderColor: design ? utils.getColor(design.borderColor) : null,
  // };

  const [showTable, setShowTable] = useState(!type);

  const dataMemo = useMemo(
    () =>
      rows
        ? _.map(rows, (r) => ({
            rowDesigns: r.rowDesigns,
            formLayoutName: r.formLayoutName,
            rowID: r.rowID,
            viewed: r.viewed,
            ...getRowData(r, columns),
          }))
        : [],
    [rows, columns]
  );

  const columnsMemo = useMemo<FwTableColumnProps[]>(
    () =>
      columns
        ? columns
            .sort(
              (c1: { position: number }, c2: { position: number }) =>
                c1.position - c2.position
            )
            .map((mapCol: Column) => {
              const { key, name } = mapCol;
              const header = _.find(headers, { key });
              const footer = _.find(footers, { key });

              return {
                Header: (table: UseTableInstanceProps<object>) =>
                  useHeaderFooter({
                    headerFooter: header,
                    table,
                    mapCol,
                    defaultValue: name,
                    store,
                  }),
                Footer: (table: UseTableInstanceProps<object>) =>
                  useHeaderFooter({
                    headerFooter: footer,
                    table,
                    mapCol,
                    store,
                  }),
                accessor: (dataMemo) => dataMemo[key],
                id: key,
                Cell: ({ value, row }: { value: string; row: Row }) => (
                  <FwData
                    key={row.id}
                    type={mapCol.type}
                    value={
                      mapCol.key?.includes('|') ? _.split(value, '\\') : value
                    }
                  />
                ),
              };
            })
        : [],
    [columns, footers, headers, store]
  );

  const MaskContainer = type ? getMaskComponent(type) : undefined;

  const maskProps: FwMaskCommonProps = {
    ...props,
    loading,
    zoomed,
    maskStructure: maskData,
    maskRows: getMaskRows(data, columns, type, maskData?.document?.colors),
  };

  const divStyle = getContainerStyle({ height, type, zoomed });

  const tableHeigth = getTableHeigth(height, type);

  return (
    <FwSegment {...style} noOverflow>
      <chakra.div {...divStyle} display={showTable ? 'none' : undefined}>
        {MaskContainer && <MaskContainer {...maskProps} />}
      </chakra.div>
      <chakra.div display={!showTable ? 'none' : undefined}>
        <FwTable
          {...props}
          sortable
          selectable
          collapse={collapsed}
          compact={compact}
          columns={columnsMemo}
          inputs={columns}
          loading={loading}
          data={dataMemo}
          hideGlobalFilter={!allowSearchAll}
          currentPage={currentPage}
          totalPage={totalPage}
          totalRow={totalRow}
          rowPerPage={rowPerPage}
          showFooter={!_.isEmpty(footers)}
          showSubFieldData={showSubFieldData}
          height={tableHeigth}
          responsive={responsive}
          wrap={wrap}
          // todo #585 move this settings to config object?
          allowContextMenu={!disableContext}
          allowDoubleClick={!disableDoubleClick}
          allowViewed={!disableViewed}
        />
      </chakra.div>
      {!dataType && (
        <Grid templateColumns="repeat(2, 1fr)" alignItems="center" mt={'5px'}>
          <GridItem>
            {type && (
              <FwParagraph small>
                <FwLink
                  leftIcon={showTable ? iconByType[type] : 'RiGridFill'}
                  onClick={() => setShowTable(!showTable)}
                >
                  {showTable ? t('See ' + textByType[type]) : t('See table')}
                </FwLink>
              </FwParagraph>
            )}
          </GridItem>
          <GridItem ml="auto">
            <Skeleton isLoaded={!loading}>
              <FwMenu
                trigger={<FwButton small leftIcon="RiMoreLine" />}
                items={menuItems}
              />
            </Skeleton>
          </GridItem>
        </Grid>
      )}
    </FwSegment>
  );
};

FwMask.propTypes = fwMaskPT;

export default FwMask;
