import { dtoCtor } from '../helper';
import { BaseFileInfo } from './baseFileInfo';

class TemplateFile extends BaseFileInfo {
  dtoName?: string;

  constructor(props?: Omit<TemplateFile, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'TemplateFile', props);
  }
}

export { TemplateFile };
