import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useApi } from 'api/useApi';
import userApi from 'api/user/userApi';
import userFilterApi from 'api/user/userFilterApi';
import { FwSpinner } from 'components/base';
import { AppUser, Identity } from 'core/model';
import { pushUrl, setTableStruct } from 'scenes/dashboard/helpers/admin';

import filterTableStructure from '../../helpers/filterTableStructure';
import User from './User';

const UserContainer: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useParams<{ name: string }>();
  const isRegister = !name;

  const argsUser = useRef(isRegister ? [] : [name]);
  const { fetched, pending } = useApi(userApi.get, argsUser.current);

  const getNewItdentity = (appUser: AppUser) => {
    const newIdentity = new Identity(appUser);
    newIdentity.new = true;
    return newIdentity;
  };

  const [identity, setIdentity] = useState(
    isRegister ? getNewItdentity(new AppUser()) : undefined
  );

  const [argsDelete, setArgsDelete] = useState([]);
  const {} = useApi(userFilterApi.delete, argsDelete);

  // receive user from backend and set in state
  useEffect(() => {
    if (!pending && fetched) {
      const newIdentity = getNewItdentity(fetched.appUser);

      // indeterminate token used in FwInput.Password
      // to have the correct placeholder
      newIdentity.token = '••••••';

      setIdentity(newIdentity);
    }
  }, [fetched, pending]);

  const handleSetUsername = (username: string) => {
    // get new identity and initialize roles to empty array
    const newIdentity = { ...identity, username: username, roles: [] };

    // set in state
    setIdentity(newIdentity);
  };

  const handleAddRow = () => {
    pushUrl(navigate, location, 'newFilterUser', { identityId: identity.id });
  };

  const handleDeleteRow = (_rowNumber: number, fieldId: string) => {
    // delete userFilter
    setArgsDelete([fieldId, identity.id]);
  };

  // set filter on page contents table
  const tableStruct = setTableStruct(
    'UserID',
    identity?.id,
    filterTableStructure
  );

  // render
  return !pending && identity ? (
    <User
      identity={identity}
      tableStruct={tableStruct}
      handleAddRow={handleAddRow}
      handleDeleteRow={handleDeleteRow}
      handleSetUsername={handleSetUsername}
    />
  ) : (
    <FwSpinner />
  );
};

export default UserContainer;
