import { InferProps, node } from 'prop-types';
import React, { useCallback, useState } from 'react';

import { Module, Page } from 'core/model';
import { setLastCustomPage as storeLastCustomPage } from 'core/utils/storage';
import utils from 'core/utils/utils';

import { useFwArea } from '../area';
import { FwPageContext } from './FwPageContext';

const FwPageProvider = ({ children, initPage }: Props) => {
  const { area } = useFwArea();

  const [page, setPage] = useState<Page>(initPage);

  const setAndStorePage = useCallback(
    (page: Page) => {
      if (page) {
        storeLastCustomPage(
          utils.buildUrlNav(
            area,
            new Module({ key: page.module, position: -1 }),
            page.position > 0 ? page.key : ''
          )
        );
      }

      setPage(page);
    },
    [area]
  );

  return (
    <FwPageContext.Provider value={[page, setAndStorePage]}>
      {children}
    </FwPageContext.Provider>
  );
};

const propTypes = {
  children: node,
};

interface Props extends InferProps<typeof propTypes> {
  initPage?: Page;
}

FwPageProvider.propTypes = propTypes;

export { FwPageProvider };
