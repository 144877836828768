import { Area } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '../admin';

const areableAS: AdminStructure<{ areas: Area[] }> = [
  {
    key: 'areas',
    name: 'Areas',
    type: FIELD_TYPE.multiselect,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const areableRules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'areas',
    },
    script: {
      scriptID: 1,
      description: 'Get options from area api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`area`).then(res => res && res.data.areas && callback({ options: {'areas': res.data.areas.map(a => ({ key: a.key, value: a.key, text: a.key, items: null })) } }))",
    },
  },
];

export { areableAS, areableRules };
