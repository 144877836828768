import { Divider, InputGroup } from '@chakra-ui/react';
import _ from 'lodash';
import { any, bool, InferProps, shape, string } from 'prop-types';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import autocompleteApi from 'api/form/autocompleteApi';
import { useApi } from 'api/useApi';
import { FwPop, FwSuggestion } from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';
import getValueMatch from 'core/utils/pattern/getValueMatch';
import utils from 'core/utils/utils';

import { useStringFwPop } from '../../pop/useFwPop';
import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';

const { reference } = FIELD_TYPE;

let timer = null;
const WAITING_INPUT = 500;

const FwAutocomplete: FC<Props & CommonInputProps> = (props) => {
  const {
    // direct props
    custom,
    editable = true,
    fieldID,
    pattern,
    loading: loadingSuggestions,
    suggestions,
    // common input props
    defaultValue,
    name,
    value,
    referenceValue,
    type,
    onChange,
  } = props;
  const defaultText =
    type === reference
      ? (referenceValue || {}).inputValue || defaultValue || ''
      : value || defaultValue || '';
  const [suggestionOptions, setSuggestionOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // api
  const [args, setArgs] = useState([]);
  const { fetched: fetchedSuggestions, pending: pendingSuggestions } = useApi(
    custom ? undefined : autocompleteApi.getSuggestions,
    custom ? undefined : args
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    data: { name: string; value: string; fillData?: object }
  ) => {
    const { name, value } = data;

    const newData = {
      name,
      value:
        type === reference ? { ...referenceValue, inputValue: value } : value,
    };

    setDisplayValue(value);
    onChange?.(e, utils.getNameValueFromData(newData));
  };

  const {
    displayValue,
    popProps,
    baseInputProps,
    setDisplayValue,
    setContentValue,
  } = useStringFwPop(
    {
      ...props,
      editable,
      name,
      searchable: true,
      value: defaultText,
      onChange: handleChange,
    },
    {
      rightIcon: 'RiSearchLine',
      onRightIconClick: null,
    }
  );

  // clear timer when unmounted
  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (custom) {
      if (suggestions) {
        if (!loadingSuggestions) {
          // pass controlled suggestions to state
          setSuggestionOptions(suggestions.result);
          setLoading(false);
        }
      } else {
        // no suggestions
        setSuggestionOptions([]);
        setLoading(false);
      }
    }
  }, [custom, loadingSuggestions, suggestions]);

  useEffect(() => {
    if (!custom) {
      if (!pendingSuggestions && fetchedSuggestions) {
        const newSuggestions = [];

        if (
          fetchedSuggestions.suggestions &&
          fetchedSuggestions.keyword === displayValue.trim()
        ) {
          _.forEach(fetchedSuggestions.suggestions, (suggestion, index) => {
            const { documentID, autoFill } = suggestion;

            newSuggestions.push({
              key: index,
              documentID: documentID,
              title: suggestion.value,
              fields: autoFill,
            });
          });
        }

        setLoading(false);
        setSuggestionOptions(newSuggestions);
      }
    }
  }, [custom, displayValue, pendingSuggestions, fetchedSuggestions]);

  const getSuggestions = (fieldId: string, fieldValue: string) => {
    setArgs([fieldId, fieldValue]);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.target;

    let newValue = element.value || '';

    if (!_.isNil(pattern)) {
      const sEn = element.selectionEnd;
      newValue = getValueMatch(pattern, displayValue, element.value, sEn);
    }

    setDisplayValue(newValue);

    if (custom) {
      // set loading state
      setLoading(true);
    } else {
      clearTimeout(timer);

      timer = setTimeout(() => {
        if (newValue) {
          setLoading(true);
          getSuggestions(fieldID, newValue.trim());
        }
      }, WAITING_INPUT);
    }
  };

  const handleClickChange = (e: ChangeEvent, { documentID, title, fields }) => {
    setLoading(false);
    setContentValue(title);

    // map auto complete data to newData
    const newData = {};

    newData[name] =
      type === reference
        ? {
            ...referenceValue,
            refDocID: documentID,
            inputValue: title,
          }
        : title;

    const refKey = type === reference ? `${name}|` : '';

    // update linked inputs. If type reference then add prefix of ref key
    _.forEach(fields, ({ key, value }) => {
      newData[`${refKey}${key}`] = value;
    });

    // trigger change for all
    onChange?.(e, { name, value: title, fillData: newData });
  };

  return (
    <FwPop {...popProps} open={custom || displayValue ? popProps?.open : false}>
      <FwPop.Anchor>
        {/*input*/}
        <InputGroup>
          <Base
            {...baseInputProps}
            loading={loading}
            pattern={pattern}
            value={displayValue}
            onChange={handleInputChange}
          />
        </InputGroup>
      </FwPop.Anchor>
      <FwPop.Content>
        {/*dropdown menu*/}
        {_.map(suggestionOptions, (suggestionOption, index) => (
          <div key={index}>
            {index > 0 && <Divider my={2} />}
            <FwSuggestion
              title={suggestionOption.title}
              content={_.map(
                suggestionOption.fields,
                utils.getDocDataFromNameValue
              )}
              onClick={(e) => handleClickChange(e, suggestionOption)}
            />
          </div>
        ))}
      </FwPop.Content>
    </FwPop>
  );
};

const propTypes = {
  custom: bool,
  editable: bool,
  fieldID: string,
  loading: bool,
  pattern: string,
  placeholder: string,
  // todo #585 merge with value & collectionValue?
  referenceValue: shape({
    inputValue: string,
    refDocID: string,
    linkDocID: string,
  }),
  suggestions: any,
};

export type Props = InferProps<typeof propTypes> & CommonInputProps;

FwAutocomplete.propTypes = propTypes;

export default FwAutocomplete;
