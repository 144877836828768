import _ from 'lodash';
import { InferProps, oneOf, string } from 'prop-types';
import React, { FC } from 'react';

import { FwMessage } from 'components/base';
import { MODAL_TYPES } from 'core/utils/constants';

const Message: FC<Props> = ({ message, type }) => (
  <FwMessage small subtle message={message} type={type} />
);

const propTypes = {
  message: string,
  type: oneOf(_.values(MODAL_TYPES)),
};

export type Props = InferProps<typeof propTypes>;

Message.propTypes = propTypes;

export default Message;
