import { Box, chakra, ChakraProps, Image } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

import { useFwTheme } from 'components/base';
import { FwImageProps, fwImagePT } from 'core/model';

const FwImage: FC<FwImageProps> = ({
  alt,
  background,
  fallback,
  htmlHeight,
  htmlWidth,
  padding,
  size,
  src,
  onClick,
}) => {
  const { bg } = useFwTheme();
  const [loaded, setLoaded] = useState(undefined);
  const [error, setError] = useState(false);

  // if image loads correctly, set loaded state
  const handleLoad = () => {
    setLoaded(true);
  };

  // if image pops error, set loaded and error states
  const handleError = () => {
    setLoaded(true);
    setError(true);
  };

  const TestImage = (
    <chakra.img
      display="none"
      src={src}
      onLoad={handleLoad}
      onError={handleError}
    />
  );

  const bgStyle: ChakraProps = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    bg,
    backgroundSize: 'cover',
    backgroundPosition: 'center' /* ${backgroundPosition} */,
    backgroundImage: `${src ? `url(${src});` : ''}`,
    zIndex: -1000,
  };

  const imageStyle = {
    p: padding,
    sx: onClick
      ? {
          '&:hover': { cursor: 'pointer' },
        }
      : undefined,
  };

  // try loading image first, then either display children or error component
  return loaded && error ? (
    fallback || <></>
  ) : !loaded ? (
    TestImage
  ) : background ? (
    <Box {...bgStyle} />
  ) : (
    <Image
      alt={alt}
      fallback={<></>}
      src={src}
      htmlHeight={htmlHeight}
      htmlWidth={htmlWidth}
      onClick={onClick}
      boxSize={size}
      {...imageStyle}
    />
  );
};

FwImage.propTypes = fwImagePT;

export default FwImage;
