import { bool, InferProps } from 'prop-types';
import React, { FC } from 'react';

import { FwImage, useFwArea } from 'components/base';

const FwLogo: FC<Props> = ({ compact }) => {
  const { area } = useFwArea();

  const imageStyle = compact
    ? {
        padding: '3px',
        src: area.areaContent?.logoSmall || area.areaContent?.logo,
      }
    : {
        padding: '15px',
        src: area.areaContent?.logo,
      };

  return <FwImage {...imageStyle} alt="logo" />;
};

const propTypes = {
  compact: bool,
};

export type Props = InferProps<typeof propTypes>;

FwLogo.propTypes = propTypes;

export default FwLogo;
