import { Td, Tr, Center, useColorMode } from '@chakra-ui/react';
import _ from 'lodash';
import { InferProps, string, any, func, bool, arrayOf } from 'prop-types';
import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Row as RTRow } from 'react-table';

import {
  FwButton,
  FwContextMenu,
  FwGrid,
  FwField,
  useFwArea,
  useFwModule,
  useFwTheme,
} from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import {
  mapDesignToStyle,
  mapInputToField,
  mapStyleToChakra,
} from 'core/mapper';
import { FwButtonProps, Row as DtoRow } from 'core/model';
import { useMobileDoubleClick } from 'core/utils/useMobileDoubleClick';
import utils from 'core/utils/utils';

import { COLUMN_DELETE_ID } from '../FwTable';

const getRowID = (row: RTRow<DtoRow>) => row.original.rowID;
const getRowViewed = (row: RTRow<DtoRow>) => row.original.viewed;
const getRowTemplateName = (row: RTRow<DtoRow>) => row.original.formLayoutName;

// delete button component
const FwDeleteButtonCell: FC<FwButtonProps> = (props) => {
  return (
    <Center>
      <FwButton
        primary
        rounded
        small
        leftIcon={'RiIndeterminateCircleLine'}
        {...props}
      />
    </Center>
  );
};

const Row: FC<RTRowProps> = ({
  row,
  activeItem,
  processes,
  allowContextMenu,
  allowDoubleClick,
  allowViewed,
  renderDataAsInput,
  inputs,
  isInputReadOnly,
  isInputDisabled,
  invalidKey,
  loadingKeys,
  selectable,
  wrap,
  handleProcessActionClick,
  handleOpen,
  handleRowClick,
  handleDataInputBlur,
  handleDataInputChange,
  handleDeleteRow,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { area } = useFwArea();
  const { module } = useFwModule();
  const colorModeContext = useColorMode();

  // prepare logic for render
  const rowID = getRowID(row);
  const urlNav = getRowTemplateName(row)
    ? utils.buildUrlNav(area, module, rowID)
    : utils.buildUrlNavFromPath(location.pathname, rowID);
  const active = selectable && activeItem === rowID;
  const viewed = allowViewed ? getRowViewed(row) : true;
  const canRenderContextMenu = allowContextMenu && active;
  const RowRenderTag = canRenderContextMenu ? FwContextMenu : Tr;
  const handleClick = () => handleRowClick?.(rowID);
  const handleDoubleClick = () => handleOpen(rowID, false, urlNav);
  const onTouchStart = useMobileDoubleClick(handleDoubleClick);

  const handleMouseDownUp = selectable
    ? (e: MouseEvent<HTMLTableRowElement>) => {
        // if right click
        if (e.button === 2) {
          handleClick();
        }
      }
    : undefined;

  const contextMenuProps = canRenderContextMenu
    ? {
        items: getContextMenuItems(
          t,
          handleDoubleClick,
          () => handleOpen(rowID, true, urlNav),
          processes,
          (processId: string) => handleProcessActionClick(processId, rowID)
        ),
        renderTag: Tr,
        childrenRenderTag: Td,
      }
    : undefined;

  // todo wip#585 refactor with FwMask.Timeline.tsx
  const {
    _active: { bg, boxShadow },
    _hover,
  } = useFwTheme();

  // todo wip#585 refactor with FwMask.Timeline.tsx
  const rowStyle = {
    bg: active ? bg : undefined,
    boxShadow: !viewed ? boxShadow : undefined,
    _hover:
      // (prevent hover from overriding active style, despite :hover pseudo-element)
      _.merge(_hover, active ? { bg } : {}),
  };

  return (
    <RowRenderTag
      {...row.getRowProps()}
      {...contextMenuProps}
      {...rowStyle}
      onClick={handleClick}
      onMouseDown={handleMouseDownUp}
      onMouseUp={handleMouseDownUp}
      onDoubleClick={allowDoubleClick ? handleDoubleClick : undefined}
      onTouchStart={onTouchStart}
    >
      {_.map(row.cells, ({ getCellProps, render, column, row, value }) => {
        const { id } = column;
        const { index } = row;
        const input =
          renderDataAsInput && id !== COLUMN_DELETE_ID
            ? _.find(inputs, { key: id })
            : undefined;

        // set header value for responsive table
        const headerProps = render('Header')?.['props'];
        const headerValue =
          // for FwTable
          _.find(headerProps?.['columns'], {
            id: id,
          })?.Header(headerProps) ||
          // for FwCollection
          (_.isString(render('Header')) ? t(`custom|${render('Header')}`) : '');

        const design = _.find(row.original.rowDesigns, {
          key: id,
        })?.design;

        const { backgroundColor, borderColor, color } = mapStyleToChakra(
          mapDesignToStyle(design),
          colorModeContext
        );

        const tdStyle = {
          nowrap: wrap ? undefined : 'nowrap',
          ...(design
            ? {
                background: backgroundColor,
                boxShadow: `${borderColor} 0px 0px 0px 1px inset`,
                color,
                overflow: 'hidden',
              }
            : {}),
        };

        return (
          <Td data-label={headerValue} {...getCellProps()} {...tdStyle}>
            {id === COLUMN_DELETE_ID ? (
              <FwDeleteButtonCell
                disabled={isInputReadOnly || isInputDisabled}
                onClick={() => handleDeleteRow(index, rowID)}
              />
            ) : renderDataAsInput ? (
              <FwGrid
                items={[
                  {
                    ...mapInputToField(input, {
                      invalidInputKey: invalidKey,
                      loadingInputKeys:
                        loadingKeys && loadingKeys.includes(`${id}|${index}`)
                          ? [id]
                          : undefined,
                      docData: { [id]: value },
                    }),
                    row: 1,
                    column: 1,
                    // override to hide label
                    label: undefined,
                    onBlur: handleDataInputBlur,
                    onChange: (_e: unknown, { fillData, ...data }) =>
                      handleDataInputChange(
                        fillData ||
                          utils.getDocDataFromNameValue(
                            data as { name: string; value: string | object }
                          ),
                        index
                      ),
                    // set readOnly if collection or its subInput is readOnly
                    readOnly: isInputReadOnly || input.isReadOnly,
                    disabled: isInputDisabled,
                  },
                ]}
                itemComponent={FwField}
              />
            ) : (
              render('Cell')
            )}
          </Td>
        );
      })}
    </RowRenderTag>
  );
};

const propTypes = {
  activeItem: string,
  allowContextMenu: bool,
  allowDoubleClick: bool,
  allowViewed: bool,
  handleDataInputBlur: func,
  handleDataInputChange: func,
  handleDeleteRow: func,
  handleProcessActionClick: func,
  handleOpen: func,
  handleRowClick: func,
  processes: any,
  renderDataAsInput: bool,
  inputs: arrayOf(any),
  isInputReadOnly: bool,
  isInputDisabled: bool,
  invalidKey: string,
  loadingKeys: arrayOf(string),
  selectable: bool,
  wrap: bool,
};

export type RowProps = InferProps<typeof propTypes>;

interface RTRowProps extends RowProps {
  row: RTRow<DtoRow>;
}

Row.propTypes = propTypes;

export default React.memo(Row);
