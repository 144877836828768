import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import templateApi from 'api/templateFile/templateFileApi';
import { EntityInfo } from 'components';
import { FwMessage, FwSegment } from 'components/base';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, TemplateFile as F } from 'core/model';
import { MODAL_TYPES } from 'core/utils/constants';

import routesAdmin, { ROUTE_NAMES } from '../../routesAdmin';

const AdminFilesUpload = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const castF: F = Object.assign(new F(), {});
  const inputs = getAdminInputs(castF, null, t);

  const entityDoc = getEntityDoc(castF, inputs);

  const handleSetKey = () => {
    // redirect to admin files
    navigate(
      routesAdmin.find((route) => route.name === ROUTE_NAMES.files)?.path
    );
  };

  return (
    <>
      <EntityInfo
        api={templateApi}
        entityDoc={entityDoc}
        type={'templateFile'}
        title={t('common|Upload file')}
        onSetKey={handleSetKey}
      />
      <FwSegment>
        <FwMessage
          small
          type={MODAL_TYPES.warning}
          message="If the file already exists, it will be overwritten"
        />
      </FwSegment>
    </>
  );
};

export default AdminFilesUpload;
