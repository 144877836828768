import _ from 'lodash';

import { BaseFileInfo } from 'core/model';
import {
  FIELD_TYPE,
  SIGN_EXPORT_FORMAT,
  FILE_NAME_LIMIT,
} from 'core/utils/constant';
import Log from 'core/utils/log';
import { typeMapper } from 'core/utils/mapper';
import { IMAGE_TYPES } from 'core/utils/mimeTypes';

const { checkbox, file, photo, sign, reference, collection } = FIELD_TYPE;

const getValueChange = (field: any, callbackLoadedFile, required) => {
  const typeMapped = typeMapper(field.type);
  let valueChange;

  if (_.includes([checkbox], typeMapped)) {
    valueChange = required && !field.checked ? '' : _.toString(field.checked);
  } else if (_.includes([sign], typeMapped)) {
    // set state of this signature field
    valueChange = createJsonString(
      `${field.name}.${SIGN_EXPORT_FORMAT.extension}`,
      field.value
    );
  } else if (_.includes([file, photo], typeMapped)) {
    if (field.files && field.files.length === 1) {
      const file = field.files[0];
      getFileBase64(file, (result) => {
        // set state of this file or photo field
        const fileValue = createJsonString(file.name, result);
        callbackLoadedFile(fileValue);
      });
    } else {
      // if cancel then re-initialize value of its state
      valueChange = '';
    }
  } else if (field.type === 'datetime-local' && field.value.length === 16) {
    // if in format yyyy-MM-ddTHH:mm, then add seconds (:ss) at the end
    valueChange = `${field.value}:00`;
  } else if (_.includes([reference, collection], typeMapped)) {
    valueChange = field.value;
  } else {
    valueChange = _.toString(field.value);
  }

  return valueChange;
};

const createJsonString = (filename, data) => {
  // construct json
  const jsonData: BaseFileInfo = {
    filename: createFilename(filename),
    data: data,
  };

  return JSON.stringify(jsonData);
};

const createFilename = (originalFilename) => {
  const splitExtension = originalFilename.split('.');
  const extension = splitExtension.pop();

  // rebuild the name, substring to ensure the name length fit in DB, encode to base64, remove the special character in base64
  let fileName = splitExtension.join('.');
  fileName =
    fileName.length > FILE_NAME_LIMIT
      ? fileName.substr(0, FILE_NAME_LIMIT)
      : fileName;
  fileName = base64EncodeUnicode(fileName).replace(/[^a-zA-Z0-9=]/g, '');

  // add extension to the filename
  return `${fileName}.${extension}`;
};

const base64EncodeUnicode = (str) => {
  // first we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  const utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (_match, p1) {
      return String.fromCharCode(parseInt('0x' + p1, 16));
    }
  );

  return window.btoa(utf8Bytes);
};

const getFileBase64 = (file, callback) => {
  // read file upload
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    if (file.type.startsWith('image/')) {
      // is image then load preview
      const img = document.createElement('img');
      img.src = reader.result as string;
      img.onload = () => {
        // resize file
        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 768;

        let width = img.width;
        let height = img.height;

        if (width / MAX_WIDTH > height / MAX_HEIGHT) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        // create new resized file
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // convert to base64
        const dataurl = canvas.toDataURL(IMAGE_TYPES.png);

        // trigger callback with base64 image file data
        callback(dataurl);
      };
    } else {
      // is others file
      callback(reader.result);
    }
  };
  reader.onerror = function (error) {
    Log.error('Error: ', error); /* tODO popup error ? */
  };
};

export { createFilename, createJsonString };

export default getValueChange;
