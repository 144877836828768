import { dtoCtor } from '../helper';
import { AppUser } from './appUser';

class Identity extends AppUser {
  dtoName?: string;

  constructor(props?: Omit<Identity, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'Identity', props);
  }

  token?: string;
  tokenReset?: string;
  new?: boolean;
  required2FA?: boolean;
  set2FA?: boolean;
  available2FAMethods?: string[];
}

export { Identity };
