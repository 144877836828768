import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import pageApi from 'api/page/pageApi';
import { EntityInfo, Table } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, Page, page1Rules } from 'core/model';

const PageEdit: FC<Props> = ({
  page,
  tableStruct,
  handleAddRow,
  handleDeleteRow,
  handleSetKey,
  handleEntityChange,
}) => {
  const { t } = useTranslation();

  const castAPE: Page = Object.assign(new Page(), page);
  const inputs = getAdminInputs(castAPE, null, t, '0');
  const areasInputs = getAdminInputs(castAPE, null, t, '1');

  const entityDoc = getEntityDoc(page, inputs);
  const pageAreasDoc = getEntityDoc(page, areasInputs, page1Rules);

  return (
    <>
      {page && (
        <EntityInfo
          autosave
          api={pageApi}
          entityDoc={entityDoc}
          keyName={'pageID'}
          entityKey={page.pageID}
          type={'page'}
          title={'Page'}
          onSetKey={handleSetKey}
          onEntityChange={handleEntityChange}
        />
      )}
      {page?.pageID && (
        <EntityInfo
          autosave
          api={pageApi}
          entityDoc={pageAreasDoc}
          keyName={'pageID'}
          entityKey={page.pageID}
          title={'Areas'}
        />
      )}
      {page?.pageID && (
        <Table
          allowAddRow
          allowDeleteRow
          allowDoubleClick
          table={tableStruct}
          module={page}
          dataType="View_ViewContent"
          dataKey="ViewContentID"
          handleAddRow={handleAddRow}
          handleDeleteRow={handleDeleteRow}
        />
      )}
    </>
  );
};

const propTypes = {
  page: any.isRequired,
  tableStruct: any,
  handleAddRow: func.isRequired,
  handleDeleteRow: func.isRequired,
  handleSetKey: func.isRequired,
  handleEntityChange: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

PageEdit.propTypes = propTypes;

export default PageEdit;
