import { TemplateFile } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const templateFileAS: AdminStructure<TemplateFile> = [
  {
    key: 'data',
    name: 'File',
    required: true,
    type: FIELD_TYPE.file,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

export { templateFileAS };
