import { dtoCtor, Dto } from '../helper';
import { Script } from './script';

class Design implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Design, 'dtoName'>) {
    dtoCtor(this, 'Design', props);
  }

  designID?: number;
  icon?: string;
  iconColor?: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  script?: Script;
}

export { Design };
