import React, { FC } from 'react';
import { useParams, RouteProps } from 'react-router-dom';

import { FwModuleProvider, FwTemplatesProvider } from 'components/base';
import { Module } from 'core/model';

const ShareComponent: FC<RouteProps> = ({ children }) => {
  // todo ShareRoute is harcoded for PublicDoc at the moment
  // it could be generalized and refactored for a set of 'share/' urls
  // share pages could be any FasterWeb page that would be shared (publicly or with password protection)
  const { module } = useParams() as { module: string };
  const moduleKey = `public/${module}`;

  // todo maybe refactor with PrivateRoute?
  return (
    <FwTemplatesProvider lazy>
      <FwModuleProvider module={new Module({ key: moduleKey, position: 0 })}>
        {children}
      </FwModuleProvider>
    </FwTemplatesProvider>
  );
};

export default ShareComponent;
