import _ from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import areaApi from 'api/area/areaApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base/elements';
import { Area } from 'core/model';

import { FwAreaContext } from './FwAreaContext';
import { areaIsMain, mainArea } from './helper';

const FwAreaProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [args] = useState([]);
  const { fetched, pending } = useApi(areaApi.getAll, args);

  const [areas, setAreas] = useState([]);
  const [areaKey, setAreaKey] = useState<string>();

  useEffect(() => {
    if (!pending && !_.isEmpty(fetched?.areas)) {
      setAreas(
        // sort areas in reverse key alphabetical order to have main area (with empty key) always last
        _.orderBy(
          _.map(fetched?.areas, (area) =>
            areaIsMain(area)
              ? _.merge(new Area(area), mainArea)
              : new Area(area)
          ),
          'key',
          'desc'
        ) || []
      );
    }
  }, [fetched, pending]);

  const area = _.find(areas, { key: areaKey });

  return pending || _.isEmpty(areas) ? (
    <FwSpinner />
  ) : (
    <FwAreaContext.Provider value={{ area, areas, areaKey, setAreaKey }}>
      {children}
    </FwAreaContext.Provider>
  );
};

export { FwAreaProvider };
