import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import authenticationApi from 'api/authentication/authenticationApi';
import { EntityInfo, Preferences, useInputFormContainer } from 'components';
import {
  FwField,
  FwGrid,
  FwLink,
  FwSegment,
  useFwArea,
  useFwAuth,
  useFwSettings,
} from 'components/base';
import { getEntityDoc } from 'components/entity-info/helpers';
import { getAdminInputs, Identity } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

import Config2FA from './Config2FA';

const { text } = FIELD_TYPE;

const AccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { area } = useFwArea();
  const {
    user: currentUser,
    userFilters: { fixedFilters, editableFilters },
  } = useFwAuth();
  const { settings } = useFwSettings();

  const { id, username, twoFactorEnabled, authenticatorEnabled } = currentUser;
  const identity = useMemo(
    // indeterminate token to have the correct placeholder
    () => new Identity({ id, username, token: '••••••' }),
    [id, username]
  );
  const entityDoc = useMemo(
    () => getEntityDoc(identity, getAdminInputs(identity, null, t, '0')),
    [identity, t]
  );

  const redirectToFilterSelection = () => {
    const path = utils.buildUrlNav(area, null, 'filterselection');
    navigate(`${path}`);
  };

  const showEditPassword = utils.canPerform(currentUser, 'editpassword');
  const showEditFilters = utils.canPerform(currentUser, 'filterselection');

  const usernameInput = {
    key: 'Username',
    name: 'Username',
    label: t('common|Username'),
    type: text,
    isDisabled: true,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  };
  const roleInput = {
    key: 'Role',
    name: 'Role',
    label: t('common|Role'),
    type: text,
    isDisabled: true,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  };

  const infoInputs = showEditPassword
    ? [roleInput]
    : [usernameInput, roleInput];

  const { fields } = useInputFormContainer(
    false,
    undefined,
    { Username: username, Role: utils.getRole(currentUser) },
    infoInputs
  );

  return (
    <FwGrid>
      <FwSegment as={FwGrid} leftIcon="RiUserFill" name={t('common|Info')}>
        {showEditPassword && (
          <EntityInfo
            api={authenticationApi}
            entityDoc={entityDoc}
            keyName={'identityID'}
            entityKey={identity.username}
            type={'identity'}
          />
        )}
        <FwGrid items={fields} itemComponent={FwField} />
      </FwSegment>
      {(_.some(fixedFilters) || _.some(editableFilters)) && (
        <FwSegment
          as={FwGrid}
          leftIcon="RiFilter2Fill"
          name={t('common|Filters')}
        >
          {fixedFilters && (
            <FwGrid
              itemComponent={FwField}
              items={_.map(fixedFilters, ({ key, value }, index) => ({
                key,
                name: key,
                label: key,
                disabled: true,
                row: index,
                column: 1,
                value,
              }))}
            />
          )}
          {editableFilters && (
            <FwGrid
              itemComponent={FwField}
              items={_.map(editableFilters, ({ key, value }, index) => ({
                key,
                name: key,
                label: key,
                disabled: true,
                row: index,
                column: 1,
                value,
              }))}
            />
          )}
          {showEditFilters && (
            <FwLink leftIcon="RiPencilFill" onClick={redirectToFilterSelection}>
              {t('common|Edit filters')}
            </FwLink>
          )}
        </FwSegment>
      )}
      <Preferences />
      {settings?.allow2FA && (
        <Config2FA
          twoFactorEnabled={twoFactorEnabled}
          authenticatorEnabled={authenticatorEnabled}
        />
      )}
    </FwGrid>
  );
};

export default AccountPage;
