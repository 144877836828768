import _ from 'lodash';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableMask,
  Section,
  // imagePreview,
  TrackingByAddress,
} from 'components';
import {
  FwIframe,
  FwSegment,
  FwButton,
  FwPanel,
  FwProcess,
  FwPageContentStoreProvider,
  useFwTemplates,
  FwMarkdown,
  FwImage,
  FwField,
  useFwPageStore,
  useFwStore,
  FwStore,
} from 'components/base';
import {
  TextObject,
  TextTypes,
} from 'components/base/containers/mask/FwMask.structures';
import { useAsyncDebounce } from 'components/base/elements/table/FwTable.helpers';
import { executeScript } from 'components/form/components/template/helpers/executeScript';
import { mapDesignToStyle } from 'core/mapper';
import { BatchTemplate, Execution, FwProcessProps, Row } from 'core/model';
import { BUTTON_TYPE, CONTENT_TYPE, WAIT_INTERVAL } from 'core/utils/constant';
import utils from 'core/utils/utils';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import History from '../components/History';
import { getTilesValue, getValue } from './CustomPageContent.helper';

const {
  title,
  iframe,
  agenda,
  table,
  map,
  markdown,
  cards,
  trackingByAddress,
  history,
  image,
  timeline,
  section,
  tiles,
  field,
} = CONTENT_TYPE;

// todo refactor wip #803
const valueObjectToString = (value: string | TextObject, store: FwStore) => {
  return value
    ? typeof value === 'string'
      ? value
      : /* date is a DateObject */
      value.type === TextTypes.script
      ? /* text is a script */
        executeScript(value.text, {
          store,
        })
      : /* text is static */ value
    : '';
};

const CustomPageContent = ({
  pc,
  handleDocValidationScroll,
  zoomed,
  ...props
}: any) => {
  const {
    name,
    pageContentID,
    tableKey,
    type,
    additionalData,
    processScripts,
    design,
  } = pc || {};
  const { height, small, key, type: fieldType, value } = additionalData || {};
  const { t } = useTranslation();
  const { processes } = useFwTemplates();
  const { moduleStore, pageContentStore } = useFwStore();
  const { pageStore, setPageStore } = useFwPageStore() || {};

  const [initFieldValueRef] = useState(
    type === field
      ? () => {
          const initVal = valueObjectToString(value, {
            moduleStore,
            pageStore,
            pageContentStore,
          });

          return { current: initVal };
        }
      : null
  );

  // refs pass to child components
  const pcAdditionalDataRef = useRef(additionalData);
  const styleRef = useRef(mapDesignToStyle(design));

  // find processes in context
  const pcProcessesRef = useRef(
    _.compact(
      _.map(processScripts, ({ processID }) =>
        _.find(processes, (bt) => bt && bt.batchTemplateId === processID)
      )
    )
  );

  // separate pageContent processes and table processes by type
  const pcProcessBtnsRef = useRef(
    _.filter(
      pcProcessesRef.current,
      (p) => p.process.type === BUTTON_TYPE.process
    )
  );
  const tableProcessBtnsRef = useRef(
    _.filter(pcProcessesRef.current, (p) =>
      [BUTTON_TYPE.edit, BUTTON_TYPE.download].includes(p.process.type)
    )
  );

  const [processData, setProcessData] = useState<FwProcessProps>({});

  const getAdditionalBatchData = (batchID: string) => {
    const batch = _.find(processes, {
      batchTemplateId: batchID,
    }) as BatchTemplate;
    return batch.additionalData;
  };

  const updatePageStoreFilterData = useCallback(
    (key: string, value: string) => {
      setPageStore((pgStore) => ({
        ...pgStore,
        filterData: {
          ...pgStore.filterData,
          [key]: value,
        },
      }));
    },
    [setPageStore]
  );

  // did mount then update pageStoreValue
  useEffect(() => {
    if (type === field && initFieldValueRef.current) {
      updatePageStoreFilterData(key, initFieldValueRef.current);
    }
  }, [initFieldValueRef, key, type, updatePageStoreFilterData]);

  const fieldOnChange = useAsyncDebounce(
    (
      _e: ChangeEvent,
      data: { name: string; value: string; fillData?: object }
    ) => {
      // trigger request after WAIT_INTERVAL time
      const { name, value } = data;
      updatePageStoreFilterData(name, value);
    },
    WAIT_INTERVAL
  );

  const processBtnOnClick = ({
    batchTemplateId,
    process: { executions },
  }: BatchTemplate) => {
    if (executions.length > 0) {
      maskHandleProcess(batchTemplateId, undefined, executions);
    }
  };

  const maskHandleProcess = (
    batchID: string,
    row: Row,
    executions: Execution[]
  ) => {
    // sort step
    const sortedSteps = utils.sortThenMapProcessSteps(executions);

    const additionalData = getAdditionalBatchData(batchID);

    setProcessData({
      processID: batchID,
      objectData: row,
      processSteps: sortedSteps,
      processType: additionalData['process'],
      placement: additionalData['placement'],
    });
  };

  return (
    <FwPageContentStoreProvider>
      <FwProcess
        {...processData}
        docId={props?.documentID}
        docNumber={props?.docNumber}
        docData={props?.docData}
        handleDocValidationScroll={handleDocValidationScroll}
        onInputChange={props?.onChangeData}
        onResetProcess={() => setProcessData({})}
      >
        {pcProcessBtnsRef.current.length > 0 && (
          <FwSegment {...styleRef.current} basic={type === section}>
            <FwPanel center>
              {pcProcessBtnsRef.current.map((proc) => {
                const {
                  process: { key, name, design },
                } = proc;
                const { icon } = design || {};

                return (
                  <FwButton
                    key={key}
                    primary
                    rounded
                    leftIcon={icon}
                    onClick={() => processBtnOnClick(proc)}
                  >
                    {t(`custom|${name}`)}
                  </FwButton>
                );
              })}
            </FwPanel>
          </FwSegment>
        )}
        {type === section ? (
          // pc && visible ? (
          //<PageSegment basic {...segmentProps}>
          // copy data of section format JSON
          <Section {...props} section={pc} style={styleRef.current} />
        ) : (
          // ) : (
          //   <Section {...props} section={pc} />
          // )
          <>
            {/*<PageSegment embedding={[iframe, map].includes(type)} {...segmentProps}>*/}
            {type === iframe ? (
              <FwIframe
                {...styleRef.current}
                compact
                height={height}
                url={value}
              />
            ) : [table, agenda, cards, map, timeline].includes(type) ? (
              <TableMask
                documentID={props.documentID}
                mask={type !== table ? type : undefined}
                maskHandleProcess={maskHandleProcess}
                pageContentID={pageContentID}
                pcTableKey={tableKey}
                pcAdditionalData={pcAdditionalDataRef.current}
                processes={tableProcessBtnsRef.current}
                style={styleRef.current}
                zoomed={zoomed}
              />
            ) : type === trackingByAddress ? (
              <TrackingByAddress /*style={styleRef.current}*/ />
            ) : type === history ? (
              <History documentID={props.documentID} style={styleRef.current} />
            ) : type === image ? (
              <FwSegment {...styleRef.current}>
                <FwImage src={value} />
              </FwSegment>
            ) : type === tiles ? (
              <TilesPanel
                routes={getTilesValue(value, {
                  moduleStore,
                  pageStore,
                  pageContentStore,
                })}
                small={small}
              />
            ) : type === markdown ? (
              <FwSegment {...styleRef.current}>
                <FwMarkdown>
                  {getValue(value, {
                    moduleStore,
                    pageStore,
                    pageContentStore,
                  })}
                </FwMarkdown>
              </FwSegment>
            ) : type === title ? (
              <FwSegment {...styleRef.current}>
                <h3>{t(`custom|${value || ''}`)}</h3>
              </FwSegment>
            ) : type === field ? (
              <FwSegment {...styleRef.current}>
                <FwField
                  name={key}
                  label={name}
                  type={fieldType}
                  value={
                    _.includes(_.keys(pageStore?.filterData), key)
                      ? pageStore?.filterData?.[key] || ''
                      : initFieldValueRef.current
                  }
                  onChange={fieldOnChange}
                />
              </FwSegment>
            ) : (
              // default 'text'
              <FwSegment {...styleRef.current}>
                <p>{t(`custom|${value || ''}`)}</p>
              </FwSegment>
            )}
          </>
        )}
      </FwProcess>
    </FwPageContentStoreProvider>
  );
};

export default CustomPageContent;
