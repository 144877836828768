import { bool, InferProps, string } from 'prop-types';

import { AppPreference, FwSettingsProviderProps } from 'core/model';

const appPreferenceDataPT = {
  allow2FA: bool,
  allowPasswordReset: bool,
  countryCode: string,
  noCache: bool /* toggle for table (sort/filters) caching */,
};

type AppPreferenceData = InferProps<typeof appPreferenceDataPT>;

const mapAppPreferenceToSettings = (
  appPreference: Partial<AppPreference>
): FwSettingsProviderProps => {
  const { language, theme, color, smallMenu, defaultModule, data } =
    appPreference || {};
  const apd = data as AppPreferenceData;

  return appPreference
    ? {
        ...apd,
        accent: color,
        defaultModule,
        language,
        smallMenu,
        theme,
      }
    : {};
};

export { appPreferenceDataPT, AppPreferenceData, mapAppPreferenceToSettings };
