import { dtoCtor, Dto } from '../helper';

class BaseFileInfo implements Dto {
  dtoName?: string;

  constructor(props?: Omit<BaseFileInfo, 'dtoName'>) {
    dtoCtor(this, 'BaseFileInfo', props);
  }

  filename?: string;
  path?: string;
  data?: string;
}

export { BaseFileInfo };
