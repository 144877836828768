import { Route } from 'core/model';

// import Batch from '../batch/batchContainer';
import Doc from '../doc/docContainer';

const documentRoutes: Route[] = [
  // tODO {area}/{moduleKey}/{pageKey}/d/new/{templateID}
  // tODO {area}/{moduleKey}/{pageKey}/d/{documentID}
  {
    name: 'doc-with-step',
    path: '/:id/:stepId',
    exact: true,
    component: Doc,
  },
  {
    name: 'doc',
    path: '/:id',
    exact: true,
    component: Doc,
  },
  {
    name: 'new',
    path: '/new/:templateId',
    exact: true,
    component: Doc,
  },
  {
    name: 'new-with-step',
    path: '/new/:templateId/:stepId',
    exact: true,
    component: Doc,
  },
  {
    path: '/:type/:id',
    exact: true,
    component: Doc,
  },
];

export default documentRoutes;
