import ISO6391 from 'iso-639-1';
import _ from 'lodash';

import { Language } from 'core/model';
import { FIELD_TYPE, LOCALES } from 'core/utils/constant';

import { AdminStructure } from '..';

const languageOption = _.map(LOCALES, function (value, key) {
  return {
    key: value,
    value: key,
    text: ISO6391.getNativeName(key),
  };
});

const languageAS: AdminStructure<Language> = [
  // language
  {
    key: 'name',
    name: 'Name',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.searchselect,
    dropdown: {
      dropdownID: 1,
      options: languageOption,
    },
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    type: FIELD_TYPE.label,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  // languageContent
  {
    key: 'languageContent.batch',
    name: 'Batch',
    type: FIELD_TYPE.file,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'languageContent.calendar',
    name: 'Calendar',
    type: FIELD_TYPE.file,
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'languageContent.common',
    name: 'Common',
    type: FIELD_TYPE.file,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'languageContent.custom',
    name: 'Custom',
    type: FIELD_TYPE.file,
    row: 5,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'languageContent.glossary',
    name: 'Glossary',
    type: FIELD_TYPE.file,
    row: 6,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'languageContent.settings',
    name: 'Settings',
    type: FIELD_TYPE.file,
    row: 7,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'languageContent.tracking',
    name: 'Tracking',
    type: FIELD_TYPE.file,
    row: 8,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

export { languageAS };
