import { ColorModeContextType, StyleProps } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

import { getValidColor } from 'config/theme/colors';
import { StylePT } from 'core/model';

const mapStyleToLeaflet = (
  style: StylePT,
  colorModeContext: ColorModeContextType | StyleFunctionProps
): {
  fillColor: StyleProps['backgroundColor'];
  color: StyleProps['borderColor'];
  labelColor: StyleProps['color'];
} => {
  const leafletProps = {
    fillColor: getValidColor(style?.backgroundColor, colorModeContext),
    color: getValidColor(style?.borderColor, colorModeContext),
    labelColor: getValidColor(style?.color, colorModeContext),
  };
  return leafletProps;
};

export { mapStyleToLeaflet };
